import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { ScanPopupState } from "@/models/common";
import { CenterPopup, Toast } from 'antd-mobile';
import { BrowserMultiFormatReader } from "@zxing/library";
import _ from "lodash";
import { loginByQrCode } from '@/services';
import "../scan.css";

let scanner: any

export default function ScanPopup() {
  // 弹窗状态
  const [visible, setVisible] = useRecoilState(ScanPopupState);

  // 打开摄像头
  const openScanner = () => {
    try {
      scanner = new BrowserMultiFormatReader();
      scanner.listVideoInputDevices().then((devices: any) => {
        // 获取设备名称
        const label = devices[0].label;
        // 兼容设备Id
        const deviceId = devices.length > 5 ? devices[5].deviceId : devices.length === 1 ? devices[0].deviceId : label.indexOf('back') > -1 ? devices[0].deviceId : devices[1].deviceId;
        setVisible(true);
        // 解析扫描结果
        scanner.decodeFromVideoDevice(deviceId, 'video', (result: any, err: any) => {
          result ? (() => {
            if (_.startsWith(result.text, 'code_login://')) {
              const code = result.text.replace('code_login://', '');
              code ? (async () => {
                try {
                  await loginByQrCode(code);
                } catch (err) {
                  handleClose();
                }
              })() : Toast.show({ content: '授权链接错误，请重试', icon: 'fail' });
            } else {
              Toast.show({ content: '无法识别，请重试', icon: 'fail' });
            }
            handleClose();
          })() : '';
        });
      });
    } catch (err) {
      Toast.show({ content: err?.msg, icon: 'fail' });
      handleClose();
    }
  };

  // 处理关闭
  const handleClose = () => {
    // 关闭摄像头
    setVisible(false);
    scanner && scanner.reset();
  };

  // 监听
  useEffect(() => {
    visible ? openScanner() : handleClose();
  }, [visible]);

  return (
    <div className="">
      <CenterPopup visible={visible} onMaskClick={handleClose} style={{'--background-color': 'transparent'}}>
        <div className="relative">
          <video id="video" autoPlay/>
          <div className="qr-scanner">
            <div className="box">
              <div className="line"></div>
              <div className="angle"></div>
            </div>
          </div>
        </div>
        <div className="mt-3 text-center text-[14px] text-[rgba(255,255,255,.8)]">对准条形码/二维码，即可自动扫描</div>
      </CenterPopup>
    </div>
  )
}