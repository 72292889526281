import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { getFileExt } from '.';

/**
 * 上传 input 获取的文件到阿里云 OSS
 * @returns 文件 url
 */
export const uploadOss = async (file: File) => {
  const ossPath = `files/${uuidv4()}.${getFileExt(file.name)}`
  // 获取 oss 上传凭证
  const policyRes = await axios.post('https://tg2.daanjiexi.com/upload/oss_policy', {
    path: ossPath,
    bucked_host: 'https://dajxstatic.oss-cn-beijing.aliyuncs.com'
  })

  const ossPolicy = policyRes?.data?.data?.ossPolicy

  if (!ossPolicy) {
    throw new Error("获取上传凭证失败")
  }

  const formData = new FormData();
  formData.append("key", ossPath)
  formData.append("policy", ossPolicy.policy)
  formData.append("OSSAccessKeyId", ossPolicy.accessid)
  formData.append("success_action_status", '200')
  formData.append("signature", ossPolicy.signature)
  formData.append("file", file)

  await axios.post(ossPolicy.host, formData);

  return `${ossPolicy.host}/${ossPath}`
}


/**
 * 上传本地文件到阿里云 OSS
 * @param localPath 本地文件地址
 * @returns 文件 url
 */
export async function uploadOssByLocalPath(localPath: string): Promise<string> {
  const ossPath = `files/${uuidv4()}.${getFileExt(localPath)}`
  // 获取上传凭证
  const policyRes = await ajax({
    url: 'https://tg2.daanjiexi.com/upload/oss_policy',
    method: 'post',
    data: {
      body: JSON.stringify({ 
        path: ossPath,
        bucked_host: 'https://dajxstatic.oss-cn-beijing.aliyuncs.com'
      })
    }
  })

  const ossPolicy = policyRes?.data?.ossPolicy

  if (!ossPolicy) {
    throw new Error("获取上传凭证失败")
  }

  // 开始上传
  await ajax({
    url: ossPolicy.host,
    method: 'post',
    dataType: 'text',
    timeout: 100,
    returnAll: true,
    data: {
      values: {
        key: ossPath,
        policy: ossPolicy.policy,
        OSSAccessKeyId: ossPolicy.accessid,
        success_action_status: '200',
        signature: ossPolicy.signature,
      },
      files: {
        file: localPath
      }
    }
  })

  return `${ossPolicy.host}/${ossPath}`
}

// ajax 默认支持跨域
export const ajax = (params: any): Promise<any | {
  statusCode: number
  headers: Record<string, string>
  body: any,
}> => {
  return new Promise((resolve, reject) => {
    window.api.ajax(params, function (ret: any, err: {
      statusCode: number //网络请求状态码，数字类型
      code: number //错误码，数字类型。（0：连接错误、1：超时、2：授权错误、3：数据类型错误、4：不安全的数据）
      msg: string //错误描述，字符串类型
      body: any //服务器返回的原始数据
    }) {
      if (ret) {
        resolve(ret)
      } else {
        reject(err)
      }
    });

  })
}