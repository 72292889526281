import { useEffect, useState, useRef } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useToggle } from "react-use";
import { useMutation } from "react-query";
import { Popup, Avatar, Input, Toast, ActionSheet } from "antd-mobile";
import { GoodSubject, IsPass, LoginModalOpenState } from "@/models/user";
import { useLoginInfo } from "@/hooks/user";
import _ from "lodash";
import validator from "validator";
import { PHASE_INFO } from "@/constants/user";
import { uploadOss, uploadOssByLocalPath } from "@/utils/upload";
import { addTeacher } from "@/services";
import { ImageAction } from "@/models/dict";
import { isApp } from "@/utils";
import { getPicture } from "@/apicloud/api";

import PerfectBg from "@/assets/prefect-bg.png";
import DefaultAvatar from "@/assets/default-avatar.png";
import CameraIcon from "@/assets/icon/camera.png";

export default function PrefectInfo() {
  // 登录弹窗状态
  const loginModalOpenState = useRecoilValue(LoginModalOpenState);
  // 用户登录信息
  const { loginInfo, refresh } = useLoginInfo();
  // 弹窗状态
  const [outerVisible, setOuterVisible] = useToggle(false);
  const [innerVisible, setInnerVisible] = useToggle(false);
  // 擅长科目配置
  const goodSubjects = useRecoilValue(GoodSubject);
  // 教师信息
  const [formData, setFormData]: any = useState({ nickname: '', headimgurl: '', good_subject: [] });
  // 上传文件
  const uploadFileRef = useRef<HTMLElement | null>(null);
  // 图片操作
  const imageAction = useRecoilValue(ImageAction);
  // 状态
  const [isShow, toggle] = useToggle(false);
  // 是否跳过
  const [isPass, setPass] = useRecoilState(IsPass);

  /**
   * 处理更新
   * @param field 字段
   * @param value 值
   */
  const handleUpdate = (field: string, value: string) => {
    setFormData((formData: any) => ({ ...formData, [field]: value }));
  };

  // 处理选择
  const handleSelected = (data: any) => {
    const len = _.filter(formData.good_subject, { phase: data.phase }).length;
    const flag = _.find(formData.good_subject, { phase: data.phase, text: data.text });
    len === 2 && !flag ? Toast.show({ content: '每个学段最多选择2个科目' }) : '';
    const result = flag ? formData.good_subject.filter((item: any) => item.phase !== data.phase || item.phase === data.phase && item.text !== data.text) : len === 2 ? formData.good_subject : [...formData.good_subject, data];
    setFormData((formData: any) => ({ ...formData, good_subject: result }));
  };

  // 处理校验
  const handleCheck = () => {
    // 初始化
    let content = '', flag = false;
    // 解构
    const { nickname, headimgurl, good_subject } = formData;
    // 校验
    validator.isEmpty(headimgurl) ? content = '请上传头像'
      : validator.isEmpty(nickname) ? content = '请输入昵称'
        : good_subject.length === 0 ? content = '请选择擅长科目' : flag = true;
    !flag ? Toast.show(content) : setInnerVisible(true);
  };

  // 处理提交
  const handleSubmit = useMutation(async () => {
    // 解构
    const { nickname, headimgurl, good_subject } = formData;
    // 数据格式化
    const params = {
      nickname,
      headimgurl,
      primary_school_subject: good_subject.filter((item: any) => item.phase === '1').map((item: any) => item.text), // 小学科目
      middle_subject: good_subject.filter((item: any) => item.phase === '2').map((item: any) => item.text), // 初中科目
      high_subject: good_subject.filter((item: any) => item.phase === '3').map((item: any) => item.text), // 高中科目
    };
    await addTeacher(params);
    setOuterVisible(false);
    setInnerVisible(false);
    refresh();
  });

  // 处理头像
  const handleHeadPortrait = async (type: string | number) => {
    try {
      // 获取数据
      const { data }: any = await getPicture(type);
      const url = await uploadOssByLocalPath(data);
      handleUpdate('headimgurl', url);
      // 关闭
      toggle(false);
    } catch (err: any) {
      Toast.show({ content: err.message === 'user canceled' ? '取消操作' : err.message, icon: 'fail', duration: 3000 });
    }
  };

  // 监听
  useEffect(() => {
    setOuterVisible(loginInfo?.beat_subjects.length === 0 && !isPass);
    loginInfo ? setFormData({ nickname: '', headimgurl: loginInfo.avatar, good_subject: [] }) : ''
  }, [loginModalOpenState, loginInfo, isPass]);

  // 监听
  useEffect(() => {
    // 文件上传
    const uploadFile = async (e: any) => {
      const file = e.target.files[0];
      const res = await uploadOss(file);
      handleUpdate('headimgurl', res);
    };
    const fileDOM = document.getElementById('uploadFile');
    if (fileDOM) {
      uploadFileRef.current = fileDOM;
      fileDOM.addEventListener('change', uploadFile);
    }
    return () => {
      fileDOM && fileDOM.removeEventListener('change', uploadFile);
    };
  }, [uploadFileRef.current]);

  return (
    <div className="">
      <Popup visible={outerVisible} className="w-full">
        <div className="prefect-layout" style={{backgroundImage: `url(${PerfectBg})`, backgroundSize: '100% 16.75rem', backgroundRepeat: 'no-repeat'}}>
          <div className="p-6">
            <div className="flex justify-between items-center">
              <div className="text-[24px] family-PingFangSC-PingFang font-semibold text-[#232323]">完善你的个人资料</div>
              <div className="text-[14px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.65)]" onClick={() => setPass(true)}>跳过</div>
            </div>
            <div className="mt-6 relative inline-block" onClick={() => isApp() ? toggle(true) : uploadFileRef.current?.click()}>
              <Avatar src={formData.headimgurl || DefaultAvatar} style={{'--size': '5rem', '--border-radius': '2.5rem', 'border': '1px solid #232323'}}/>
              <img src={CameraIcon} alt="" className="w-7 h-7 absolute -bottom-1 -right-1"/>
            </div>
            <div className="mt-8 flex items-center">
              <div className="text-[16px] family-PingFangSC-PingFang font-semibold text-[#232323]">昵称</div>
              <Input value={formData.nickname} placeholder="请输入昵称" onChange={(val) => handleUpdate('nickname', val)} className="ml-10 flex-1"
                     style={{'--font-size': '1rem', '--color': 'rgba(35,35,35,0.65)',}}/>
            </div>
            <div className="half-1px-border mt-2.5"/>
            <div className="pt-5">
              <div className="flex items-center">
                <div className="text-[16px] family-PingFangSC-PingFang font-semibold text-[#232323]">擅长科目</div>
                <div className="text-[14px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.85)]">（每个学段最多选择 2 个科目）</div>
              </div>
              <div className="pt-1">
                {
                  goodSubjects.map((item: any) => (
                    <div key={item.phase} className="mt-3">
                      <div className="text-[15px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.85)]">{item.name}</div>
                      <div className="mt-3 grid grid-cols-3 gap-3">
                        {
                          item.subjects.map((sub: any, index: number) => (
                            <div key={index} onClick={() => handleSelected({phase: item.phase, text: sub})}
                                 className={`${_.find(formData.good_subject, {phase: item.phase, text: sub}) ? 'primary-btn' : 'default-btn'}`}>{sub}</div>
                          ))
                        }
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
          <div className="fixed left-0 bottom-0 w-full box-border bg-white safe-padding-bottom">
            <div className="half-1px-line"/>
            <div className="px-6 py-4">
              <div className="submit-btn" onClick={handleCheck}>保存</div>
            </div>
          </div>
        </div>
      </Popup>
      <Popup visible={innerVisible} onMaskClick={() => setInnerVisible(false)} className="w-full" bodyStyle={{borderTopLeftRadius: '1.25rem', borderTopRightRadius: '1.25rem'}}>
        <div className="safe-padding-bottom">
          <div className="p-4">
            <div className="flex flex-col items-center">
              <Avatar src={formData.headimgurl} style={{'--size': '5rem', '--border-radius': '2.5rem', 'border': '1px solid #232323'}}/>
              <div className="mt-3 text-[16px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.85)]">{formData.nickname}</div>
            </div>
            <div className="mt-3">
              <div className="text-[16px] family-PingFangSC-PingFang font-semibold text-[#232323]">擅长科目</div>
              <div className="mt-3 grid grid-cols-3 gap-x-4 gap-y-3">
                {
                  formData.good_subject.map((item: any, index: number) => (
                    <div key={index} className="primary-btn">{PHASE_INFO[item.phase]}{item.text}</div>
                  ))
                }
              </div>
              <div className="mt-6 text-[14px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.45)]">保存之后不能自行修改，请仔细确认</div>
              <div className={`mt-3 submit-btn ${handleSubmit.isLoading ? 'opacity-70' : ''}`} onClick={() => handleSubmit.isLoading ? '' : handleSubmit.mutate()}>确认</div>
            </div>
          </div>
        </div>
      </Popup>
      <input type="file" id="uploadFile" accept="image/*" hidden/>
      <ActionSheet visible={isShow} actions={imageAction} onClose={() => toggle(false)} cancelText="取消" onAction={action => handleHeadPortrait(action.key)} />
    </div>
  )
}