import { ReactNode } from "react";
import EmptyIcon from "@/assets/empty.png";

type Props = {
  description?: string, // 描述
  state: boolean; // 状态
  children: ReactNode, // 子组件
  empty?: boolean, // 空白
  image?: any, // 自定义图片
  marginTop?: string, // 外边距
};

export default function Nothing({ description = '暂无数据', state, children, empty = false, image = EmptyIcon, marginTop = 'mt-4' }: Props) {
  return (
    <div className="">
      { state ? <div className="">{ children }</div> : !empty ? <div className={`${marginTop} flex flex-col items-center`}>
        <img src={image} alt="" className="w-[201px] h-[188px]" />
        <div className="mt-4 text-[14px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.65)]">{description}</div>
      </div> : ''}
    </div>
  )
}
