import { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { useToggle } from "react-use";
import { Form, Input, Toast, Button, Popup, Image } from "antd-mobile";
import { InputRef } from "antd-mobile/es/components/input";
import validator from "validator";
import { answerAxios } from "@/utils/axios";
import { useRecoilState } from "recoil";
import { LoginModalOpenState } from "@/models/user";
import { useLoginInfo } from "@/hooks/user";

import Close from "@/assets/icon/close.png";
import Logo from "@/assets/icon/logo.png";
import Mobile from "@/assets/icon/mobile.png";
import Password from "@/assets/icon/password.png";
import Captcha from "@/assets/icon/captcha.png";

type Props = {
  otherParams?: any, // 其他参数
  onSuccess?: () => void, // 处理回调
};

// 定时器
let timer: any;

export default function LoginModal({ otherParams = {}, onSuccess }: Props) {
  // 刷新
  const { refresh } = useLoginInfo();
  // 是否展示
  const [open, setOpen] = useRecoilState(LoginModalOpenState);
  // 输入框焦点
  const inputRef = useRef<InputRef>(null);
  // 表单
  const [form] = Form.useForm();
  // 当前模式
  const [mode, setMode] = useToggle(false);
  // 加载状态
  const [loading, setLoading] = useState(false);
  // 倒计时
  const [time, setTime] = useState(0);
  // 默认值
  const second = 60;

  // 处理校验
  const handleSubmitCheck = (check = false) => {
    const result = !!document.getElementById("captcha-input") // 是否为短信登录
    // 初始化
    let content = '', flag = false;
    // 获取数据
    const { mobile, password, captcha_code } = form.getFieldsValue();
    // 校验
    !validator.isMobilePhone(mobile ? mobile : '', "zh-CN") ? content = '请输入正确的手机号码'
      : result ? time === 0 || check || !validator.isEmpty(captcha_code) ? flag = true : content = '请输入验证码'
        : validator.isEmpty(password) ? content = '请输入密码' : flag = true;
    !flag ? Toast.show(content) : '';
    return flag;
  };

  // 验证码登录
  const doLoginByCaptcha = useMutation(async () => {
    const { mobile, captcha_code } = form.getFieldsValue();
    if(!mobile) {
      Toast.show("请输入手机号")
      return
    }

    if(!captcha_code) {
      Toast.show("请输入验证码")
      return
    }
    try {
      const data = await answerAxios.post(`/api/no_auth/auth/login_by_captcha`, { mobile, captcha: captcha_code, ...otherParams })
      if (data?.token) {
        // 提示
        Toast.show({ content: "登录成功", icon: "success" });

        localStorage.setItem('token', data?.token);
        refresh();
        setOpen(false);

        if (onSuccess && typeof onSuccess === "function") {
          onSuccess();
        }
      }
    } catch (error) {
      Toast.show({ content: error?.message, icon: 'fail' });
    }
  });

  // 阿里云验证码回调
  const captchaVerifyCallback = async (captchaVerifyParam: any) => {
    const flag = !!document.getElementById("captcha-input") // 是否为短信登录
    if (handleSubmitCheck(!flag)) {
      const { mobile, password } = form.getFieldsValue();
      setLoading(true);
      if (flag) {
        // 发送短信验证码
        const { code, msg }: any = await answerAxios.post(`/api/may_auth/sms/send_captcha/login/${mobile}`, {
          ali_captcha: captchaVerifyParam
        }, { meta: true });
        if (code != 0) {
          Toast.show(msg);
          setLoading(false);
          return { captchaResult: code != "10037", bizResult: false };
        }
        // 提示
        Toast.show('验证码已发送');
        setLoading(false);
        // 初始化
        setTime(second);
        return { captchaResult: true, bizResult: true };
      } else {
        // 账号密码登录
        const { code, data, msg }: any = await answerAxios.post(`/api/no_auth/auth/login`, { username: mobile, password, ali_captcha: captchaVerifyParam }, { meta: true });
        if (code != 0) {
          Toast.show(msg);
          setLoading(false);
          return { captchaResult: code != "10037", bizResult: false };
        }
        if (code == 0 && data?.token) {
          // 提示
          Toast.show({ content: "登录成功", icon: "success" });

          localStorage.setItem('token', data?.token);
          refresh();
          setOpen(false);

          if (onSuccess && typeof onSuccess === "function") {
            onSuccess();
          }
          setLoading(false);
          return { captchaResult: true, bizResult: true };
        }
      }
    }
    return { captchaResult: true, bizResult: false };
  };

  // 验证结果回调
  const onBizResultCallback = (bizResult: boolean) => {
    const flag = !!document.getElementById("captcha-input") // 是否为短信登录
    if(bizResult && !flag) {}
  };

  // 初始化
  useEffect(() => {
    timer && clearInterval(timer);
    return () => timer && clearInterval(timer);
  }, []);

  // 定时器
  useEffect(() => {
    time === second ? timer = setInterval(() => setTime(time => --time), 1000) : time <= 0 ? clearInterval(timer) : '';
  }, [time]);

  // 初始化
  useEffect(() => {
    // 聚焦输入框
    open ? (() => {
      inputRef.current?.focus();
      form.resetFields();
      form.setFieldsValue({ mobile: '', password: '', captcha_code: '' });

      setTimeout(() => {
        // 暂时先屏蔽阿里云验证码，直接触发登录
        document.getElementById("verify-ali-captcha")?.addEventListener('click', () => {
          timer ? "" : captchaVerifyCallback("");
        })
      }, 50);

      // let captcha;
      //
      // // 绑定验证码实例函数。该函数为固定写法，无需修改
      // const getInstance = (instance: any) => {
      //   captcha = instance;
      // }
      //
      // // 初始化阿里云验证码
      // const init = () => {
      //   // https://help.aliyun.com/document_detail/2360563.html?spm=a2c4g.2360571.0.0.27e13ffffGTcfD
      //   window.initAliyunCaptcha({
      //     SceneId: '1is2q287',
      //     prefix: 'lbh0py',
      //     mode: 'embed',
      //     element: '#captcha',
      //     button: '#verify-ali-captcha',
      //     captchaVerifyCallback,
      //     onBizResultCallback,
      //     getInstance,
      //     slideStyle: { width: 311, height: 40 },
      //   });
      // }
      //
      // // 存在 js 加载慢点情况
      // const interval = setInterval(() => {
      //   if(window.initAliyunCaptcha) {
      //     init();
      //     clearInterval(interval)
      //   }
      // }, 100);
    })() : '';
  }, [open, mode]);

  // // 监听
  // useEffect(() => {
  //   const handleStorage = (e: any) => {
  //     // 解构
  //     const { key, newValue } = e;
  //     !open && key === 'token' && !newValue && setOpen(true);
  //   };
  //   window.addEventListener('storage', handleStorage);
  //   return () => window.removeEventListener('storage', handleStorage);
  // }, []);

  return (
    <Popup forceRender={true} position="right" visible={open} className="login-popup" destroyOnClose={true}>
      <Form form={form} layout="horizontal" className="login-form h-screen overflow-y-auto" footer={
        <div className="login-button">
          {
            mode ? (
              <div id="verify-ali-captcha">
                <Button block type="submit" loading={loading} style={{ '--border-radius': '0.75rem', '--background-color': '#FAE04D', '--text-color': 'rgba(35,35,35,0.85)', '--border-color': '#FAE04D' }}>
                  登录
                </Button>
              </div>
            ) : (
              <Button block type="submit" onClick={() => doLoginByCaptcha.mutate()} loading={doLoginByCaptcha.isLoading || loading} style={{ '--border-radius': '0.75rem', '--background-color': '#FAE04D', '--text-color': 'rgba(35,35,35,0.85)', '--border-color': '#FAE04D' }}>
                登录
              </Button>
            )
          }
        </div>
      }>
        <Form.Header>
          <div className="safe-padding-top">
            <Image src={Close} style={{ '--width': '1.25rem' }} onClick={() => setOpen(false)} className="relative top-5" />
            <div className="mt-16 flex flex-col items-center">
              <img src={Logo} alt="" className="w-20 h-20" />
              <div className="mt-4 text-[16px] family-PingFangSC-PingFang text-[#232323]">知嘛众包</div>
              <div className="mt-1 text-[16px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.45)]">服务人人 知嘛开门</div>
            </div>
            <div className="mt-14 flex items-center family-PingFangSC-PingFang">
              <div onClick={() => setMode(true)} className={`${mode ? 'text-[20px] font-semibold text-[#232323]' : 'text-[16px] text-[rgba(35,35,35,0.85)]'}`}>密码登录</div>
              <div onClick={() => setMode(false)} className={`ml-8 ${mode ? 'text-[16px] text-[rgba(35,35,35,0.85)]' : 'text-[20px] font-semibold text-[#232323]'}`}>验证码登录</div>
            </div>
          </div>
        </Form.Header>
        <Form.Item name="mobile">
          <div className="flex items-center">
            <img src={Mobile} alt="" className="w-5 h-5"/>
            <Input type="tel" maxLength={11} placeholder="请输入手机号" ref={inputRef}/>
          </div>
        </Form.Item>
        {
          mode ? <Form.Item name="password">
              <div className="flex items-center">
                <img src={Password} alt="" className="w-5 h-5"/>
                <Input type="password" placeholder="请输入密码"/>
              </div>
            </Form.Item>
            :
            <div id="captcha-input">
              <div className="login-captcha">
                <Form.Item name="captcha_code" extra={time === 0 ? <div id="verify-ali-captcha" className="captcha-box">发送验证码</div> : <div className="captcha-box">{time}秒后可重新获取</div>}>
                  <div className="flex items-center">
                    <img src={Captcha} alt="" className="w-5 h-5"/>
                    <Input type="tel" maxLength={6} placeholder="请输入验证码"/>
                  </div>
                </Form.Item>
              </div>
            </div>
        }
        <div id="captcha" className="mt-4 flex justify-center w-full"/>
      </Form>
    </Popup>
  );
}
