import { ReactNode } from "react";
import BottomTab from "@/components/BottomTab";
import PrefectInfo from "@/components/PerfectInfo";
import HintPopup from "@/components/HintPopup";
import ServicePopup from "@/components/ServicePopup";
import ScanPopup from "@/components/ScanPopup";

type Props = {
  paddingTop?: boolean, // 处理安全边距
  paddingBottom?: boolean, // 处理安全边距
  children: ReactNode, // 子组件
};

export default function Layout({ paddingTop = true, paddingBottom = true, children }: Props) {

  return (
    <div className={`${paddingTop ? 'safe-padding-top' : ''} ${paddingBottom ? 'safe-padding-bottom' : ''} layout`}>
      {children}
      <PrefectInfo />
      <HintPopup />
      <ServicePopup />
      <ScanPopup />
      <BottomTab/>
    </div>
  )
}