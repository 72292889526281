import { answerAxios } from "@/utils/axios";

// 任务大厅列表
export const teacherTaskList = (period_subject: string): Promise<{ list: TaskInfo[] }> => answerAxios.get(`/api_go/may_auth/teacher_answer/teacher_task_list`, { params: { period_subject }});

// 获取任务信息
export const getTaskInfo = (task_id: number): Promise<{ data: TaskDetail, code: number, msg: string }> => answerAxios.get(`/api_go/may_auth/teacher_answer/teacher_task_detail`, { params: { task_id }, meta: true, errMsg: false });

// 接单
export const orderTask = (data: { task_id: number }): Promise<{ data: any, code: number, msg: string }> => answerAxios.post(`/api_go/may_auth/teacher_answer/order_task`, data, { meta: true, errMsg: false });

// 获取进行中任务列表
export const teacherHandlerTasks = (): Promise<{ list: TaskInfo[] }> => answerAxios.get(`/api_go/may_auth/teacher_answer/teacher_handler_tasks`, {});

// 获取已完成任务列表
export const teacherEndTasks = (cursor: string | undefined | null): Promise<{ list: TaskInfo[], cursor: string | null }> => answerAxios.get(`/api_go/may_auth/teacher_answer/teacher_end_tasks`, { params: { cursor }});

// 完成任务
export const endTask = (data: { task_id: number }) => answerAxios.post(`/api_go/may_auth/teacher_answer/end_task`, data, {});

// 取消任务
export const teacherCancelTaskPendingTask = (data: { task_id: number | undefined, cancel_reason: string }) => answerAxios.post(`/api_go/may_auth/teacher_answer/teacher_cancel_task`, data, { meta: true, errMsg: false });

// 获取任务评论列表
export const teacherAnswerComment = (task_id: number, cursor: string | undefined | null) => answerAxios.get(`/api_go/may_auth/teacher_answer/teacher_answer_comment`, { params: { task_id, cursor }});
