// 学段信息
export const PHASE_INFO = {
  '1': '小学',
  '2': '初中',
  '3': '高中',
};

// 年级信息
export const GRADE_DATA = {
  '11': '一年级',
  '12': '二年级',
  '13': '三年级',
  '14': '四年级',
  '15': '五年级',
  '16': '六年级',
  '21': '初一',
  '22': '初二',
  '23': '初三',
  '31': '高一',
  '32': '高二',
  '33': '高三',
};

export const WITHDRAW_KEY: any = {
  1: '已打款',
  2: '待打款',
  3: '待审核',
  4: '提现驳回',
  5: '审核中',
};

export const WITHDRAW_PAYMENT: any = {
  1: '微信',
  2: '支付宝',
  4: '微信投稿小程序',
  5: '答案解析微信小程序',
};