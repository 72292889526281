import { atom } from "recoil";

// 筛选条件
export const ScreenData = atom<BeatSubject[] | []>({
  key: 'ScreenData',
  default: [],
});

// 任务详情
export const TaskDetail = atom<TaskDetail | null>({
  key: 'TaskDetail',
  default: null,
});
