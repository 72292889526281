import Layout from "@/components/Layout";
import SafeNav from "@/components/SafeNav";

export default function Contact() {
  return (
    <Layout paddingBottom={false}>
      <SafeNav title="联系我们" isService={false}/>
      <div className="h-full bg-[#F7F7F7]">
        <div className="pt-[42px] bg-white">
          <div className="px-4 py-3 flex justify-between items-center">
            <div className="text-[15px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.85)]">联系电话</div>
            <div className="ml-3 flex-1 text-[14px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.45)] text-right truncate-1">（0531）6880 3361</div>
          </div>
          <div className="half-1px-line"/>
          <div className="px-4 py-3 flex justify-between items-center">
            <div className="text-[15px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.85)]">电子邮箱</div>
            <div className="ml-3 flex-1 text-[14px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.45)] text-right truncate-1">tima@timasky.com</div>
          </div>
          <div className="half-1px-line"/>
        </div>
      </div>
    </Layout>
  )
}