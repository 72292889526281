import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useToggle } from "react-use";
import Layout from "@/components/Layout";
import SafeNav from "@/components/SafeNav";
import { useLoginInfo } from "@/hooks/user";
import { Popup } from "antd-mobile";
import dayjs from "dayjs";

import RightIcon from "@/assets/icon/right.png";

export default function PersonalInventory() {
  // 用户登录信息
  const { loginInfo } = useLoginInfo();
  // 弹窗状态
  const [visible, setVisible] = useToggle(false);
  // 路由
  const navigate = useNavigate();

  // 用户信息列表
  const infoList = useMemo(() => {
    // 基础数据
    return [
      { title: '昵称', value: loginInfo?.nickname, purpose: '创建账号、对外展示', scene: '首次注册创建账号、用户自行编辑资料' },
      { title: '头像', value: loginInfo?.avatar ? '/' : '', purpose: '对外展示', scene: '用户自行编辑资料' },
      { title: '手机号', value: loginInfo?.username, purpose: '创建账号、登录', scene: '首次注册创建账号' },
      { title: '擅长科目', value: loginInfo?.beat_subjects && loginInfo?.beat_subjects.length > 0 ? '/' : '', purpose: '发放任务', scene: '任务接单时' },
      { title: '第三方账号', value: loginInfo ? '/' : '', purpose: '账号绑定', scene: '支付宝' },
      { title: '姓名', value: loginInfo ? '/' : '', purpose: '实名认证', scene: '用户提现奖金时' },
    ];
  }, [loginInfo]);

  return (
    <Layout paddingBottom={false}>
      <SafeNav title="个人信息收集清单" isService={false}/>
      <div className="h-full bg-[#F7F7F7]">
        <div className="pt-[42px]">
          <div className="p-4 bg-white text-sm text-[rgba(36,36,36,0.65)] break-all leading-5">
            知嘛众包将尽全力保障你的个人隐私和信息安全，保障你在个人信息处理中的权益。你可以查看个人信息的收集、处理情况。
          </div>
          <div className="p-3 text-[14px] text-[rgba(36,36,36,0.65)]">个人信息列表</div>
          <div className="bg-white">
            <div className="px-4 py-3 flex justify-between items-center" onClick={() => setVisible(true)}>
              <div className="text-[15px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.85)]">个人资料</div>
              <img src={RightIcon} alt="" className="ml-1.5 w-4 h-4"/>
            </div>
          </div>
          <div className="p-3 text-[14px] text-[rgba(36,36,36,0.65)]">查看其他信息</div>
          <div className="bg-white">
            <div className="px-4 py-3 flex justify-between items-center">
              <div className="text-[15px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.85)]">注册时间</div>
              <div className="text-[13px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.65)]">{dayjs(loginInfo?.created_at).format("YYYY-MM-DD")}</div>
            </div>
            <div className="half-1px-line"/>
            <div className="px-4 py-3 flex justify-between items-center" onClick={() => navigate(`/deviceProperties`)}>
              <div className="text-[15px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.85)]">设备属性信息</div>
              <img src={RightIcon} alt="" className="ml-1.5 w-4 h-4"/>
            </div>
          </div>
        </div>
      </div>
      <Popup position="right" visible={visible}>
        <Layout paddingBottom={false}>
          <SafeNav title="个人资料" isService={false} handleCustom={() => setVisible(false)}/>
          <div className="min-h-screen w-screen bg-[#F7F7F7]">
            <div className="pt-[42px]">
              {
                infoList.map((item: any, index: number) => (
                  <div key={index} className="">
                    <div className="p-3 text-[14px] text-[rgba(36,36,36,0.65)]">{item?.title}</div>
                    <div className="bg-white p-3 text-[14px] text-[rgba(36,36,36,0.85)] leading-6 break-all">
                      <div className="">使用目的：{item?.purpose}</div>
                      <div className="">使用场景：{item?.scene}</div>
                      <div className="">收集情况：{item?.value ? '已收集' : '未收集'}</div>
                      <div className="">信息内容：{item?.value}</div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </Layout>
      </Popup>
    </Layout>
  )
}