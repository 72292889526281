import { useNavigate } from "react-router-dom";
import Layout from "@/components/Layout";
import SafeNav from "@/components/SafeNav";
import { useDebugMode } from "@/utils";

import Logo from "@/assets/icon/logo.png";
import RightIcon from "@/assets/icon/right.png";

export default function About() {
  // 路由
  const navigate = useNavigate();
  // 调试
  const { click } = useDebugMode();

  return (
    <Layout paddingBottom={false}>
      <SafeNav title="关于我们" isService={false}/>
      <div className="h-full bg-[#F7F7F7]">
        <div className="pt-[42px]">
          <div className="py-16 text-center" onClick={click}>
            <img src={Logo} alt="" className="w-24 h-24"/>
          </div>
          <div className="bg-white">
            <div className="half-1px-line"/>
            <div className="px-4 py-3 flex justify-between items-center" onClick={() => navigate(`/agreement/1809`)}>
              <div className="text-[15px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.85)]">用户协议</div>
              <img src={RightIcon} alt="" className="ml-1.5 w-4 h-4"/>
            </div>
            <div className="half-1px-line"/>
            <div className="px-4 py-3 flex justify-between items-center" onClick={() => navigate(`/agreement/1810`)}>
              <div className="text-[15px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.85)]">隐私政策</div>
              <img src={RightIcon} alt="" className="ml-1.5 w-4 h-4"/>
            </div>
            <div className="half-1px-line"/>
            <div className="px-4 py-3 flex justify-between items-center" onClick={() => navigate(`/report`)}>
              <div className="text-[15px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.85)]">举报信息</div>
              <img src={RightIcon} alt="" className="ml-1.5 w-4 h-4"/>
            </div>
            <div className="half-1px-line"/>
            <div className="px-4 py-3 flex justify-between items-center" onClick={() => navigate(`/contact`)}>
              <div className="text-[15px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.85)]">联系我们</div>
              <img src={RightIcon} alt="" className="ml-1.5 w-4 h-4"/>
            </div>
            <div className="half-1px-line"/>
          </div>
          <div className="mt-20 text-center text-[rgba(35,35,35,0.85)] leading-6">
            <div>Copyright ©2024</div>
            <div>山东天码网络科技有限公司版权所有</div>
          </div>
        </div>
      </div>
    </Layout>
  )
}