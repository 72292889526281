export const TASK_ITEM_COLOR = {
  '语文': 'bg-[#E6ECFF] text-[#4977F4]',
  '数学': 'bg-[#DBF1DC] text-[#71C974]',
  '英语': 'bg-[#FFE8E5] text-[#FF5C4E]',
  '物理': 'bg-[#FEF4C1] text-[#B59B05]',
  '化学': 'bg-[#E6ECFF] text-[#4977F4]',
  '生物': 'bg-[#DBF1DC] text-[#71C974]',
  '地理': 'bg-[#FFE8E5] text-[#FF5C4E]',
  '历史': 'bg-[#FEF4C1] text-[#B59B05]',
  '政治': 'bg-[#E6ECFF] text-[#4977F4]',
  '道德与法治': 'bg-[#DBF1DC] text-[#71C974]',
  '': 'bg-[#E6ECFF] text-[#4977F4]',
};

export const TASK_EVALUATE_STATUS = {
  '1': '未评价',
  '2': '满意',
  '3': '不满意'
};

export const TASK_EVALUATE_STATUS_COLOR = {
  '1': 'bg-[#EEEEEE] text-[rgba(35,35,35,0.45)]',
  '2': 'bg-[#71C974] text-white',
  '3': 'bg-[#FF5C4E] text-white',
};

export const TASK_EVALUATE_STATUS_TEXT_COLOR = {
  '1': 'text-[rgba(35,35,35,0.45)]',
  '2': 'text-[rgba(113,201,116,1)]',
  '3': 'text-[rgba(255,92,78,1)]',
};

export const TASK_AWARD_STATUS = {
  '1': '无需发放',
  '2': '已发放',
  '3': '发放失败',
  '4': '发放退回中',
  '5': '发放退回成功',
  '6': '发放退回失败',
  '7': '待发放',
  '8': '发放中',
};

export const TASK_AWARD_STATUS_TEXT_COLOR = {
  '1': 'text-[#71C974]',
  '2': 'text-[#71C974]',
  '3': 'text-[#FFA745]',
  '4': 'text-[#FFA745]',
  '5': 'text-[#FFA745]',
  '6': 'text-[#FFA745]',
  '7': 'text-[#FFA745]',
  '8': 'text-[#FFA745]',
};