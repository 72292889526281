import { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { Image, InfiniteScroll, Toast } from "antd-mobile";
import Layout from "@/components/Layout";
import SafeNav from "@/components/SafeNav";
import { getWithdrawRecord } from "@/services";
import { WITHDRAW_KEY, WITHDRAW_PAYMENT } from "@/constants/user";
import copy from "copy-to-clipboard";
import _ from "lodash";

import OrderCopyIcon from "@/assets/icon/order-copy.png";

export default function WithdrawRecord() {
  // 数据列表
  const [dataList, setDataList] = useState<WithdrawRecord[]>([]);
  // 分页
  const [cursor, setCursor] = useState<string | null>('');

  // 获取提现记录
  const getData = useMutation(async (params: any) => {
    // 解构
    const { dataList, cursor } = params;
    const { record: { data, next_cursor} } = await getWithdrawRecord({ cursor });
    setDataList(_.uniqBy([...dataList, ...data], 'id'));
    setCursor(next_cursor);
  });

  // 加载更多
  const loadMore = (): any => {
    if(!getData.isLoading && dataList.length !== 0) {
      getData.mutate({ dataList, cursor });
    }
  };

  // 处理复制
  const handleCopy = (value: string, text: string) => {
    if (value) {
      copy(value);
      Toast.show({ content: `${text}复制成功`, icon: 'success' });
    }
  };

  // 初始化
  useEffect(() => {
    getData.mutate({ dataList: [], cursor: '' });
  }, []);

  return (
    <Layout paddingBottom={false}>
      <SafeNav title="提现记录"/>
      <div className="pt-[42px] min-h-screen bg-[#F7F7F7] px-4">
        {
          dataList.map(item => (
            <div key={item.id} className="mt-3 bg-white rounded-xl p-4">
              <div className="text-[15px] order-box-header">
                <div className="flex justify-between items-center font-medium">
                  <div className="">流水号：</div>
                  <div className={`${item.withdraw_status !== 1 ? 'text-[#EC6C61]' : 'text-[#71C974]'}`}>
                    {WITHDRAW_KEY[item.withdraw_status]}
                  </div>
                </div>
                <div className="mt-1 flex family-PingFangSC-PingFang font-semibold text-[#242424] break-all">
                  {item.withdraw_no}
                  <Image src={OrderCopyIcon} onClick={() => handleCopy(item.withdraw_no, '流水号')} style={{'--width': '0.9375rem', '--height': '0.9375rem'}}
                         className="inline-block align-top order-copy-icon"/>
                </div>
              </div>
              <div className="mt-3 text-[12px] family-PingFangSC-PingFang text-[rgba(36,36,36,0.65)]">申请时间：{item.apply_for_date}</div>
              <div className="mt-2 text-[12px] family-PingFangSC-PingFang text-[rgba(36,36,36,0.65)]">到账时间：{item.into_account_date}</div>
              <div className="mt-4 flex justify-end items-center text-[12px] family-PingFangSC-PingFang">
                <div className="flex items-center">
                  <div className="text-[rgba(36,36,36,0.65)]">提现方式：</div>
                  <div className="text-[14px] font-medium text-[#242424]">{WITHDRAW_PAYMENT[item.account_type]}</div>
                </div>
                <div className="ml-4 flex items-center">
                  <div className="text-[rgba(36,36,36,0.65)]">提现金额：</div>
                  <div className="text-[18px] family-D-DIN-Bold text-[#242424]">￥{item.withdraw_money}</div>
                </div>
              </div>
              {item.remark && <div className="mt-3 p-3 rounded-xl text-[12px] family-PingFangSC-PingFang text-[rgba(36,36,36,0.65)] order-box-address">
                失败原因：{item.remark}
              </div>}
          </div>
        ))
      }
      <InfiniteScroll loadMore={loadMore} hasMore={cursor !== null}/>
    </div>
  </Layout>
  )
}