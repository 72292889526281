import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { InfiniteScroll } from "antd-mobile";
import Layout from "@/components/Layout";
import Nothing from "@/components/Nothing";
import TaskItem from "@/components/TaskItem";
import { teacherHandlerTasks, teacherEndTasks } from "@/services";
import CustomSkeleton from "@/components/CustomSkeleton";
import _ from "lodash";
import { OrderCount } from "@/models/order";
import { useRecoilState } from "recoil";
import { isApp } from "@/utils";
import { useLoginInfo } from "@/hooks/user";

export default function Order() {
  // 用户登录信息
  const { loginInfo } = useLoginInfo();
  // 分页
  const [cursor, setCursor] = useState<string | null>('');
  // 已完成任务列表
  const [dataList, setDataList] = useState<TaskInfo[]>([]);
  // 订单数
  const [, setOrderCount] = useRecoilState(OrderCount);

  // 获取进行中任务
  const { data } = useQuery([teacherHandlerTasks.name], async () => {
    if (!loginInfo || (loginInfo && (loginInfo?.beat_subjects.length === 0 || loginInfo?.status !== 1 || loginInfo?.credit <= 0))) {
      return false;
    }
    getEndTasks.mutate({ dataList: [], cursor: '' });
    // 获取数据
    const { list } = await teacherHandlerTasks();
    setOrderCount((orderCount) => ({ ...orderCount, order: list.length > 0 ? list.length.toString() : '' }));
    return { list };
  });

  // 获取已完成任务列表
  const getEndTasks = useMutation(async (params: { dataList: TaskInfo[], cursor: string | null }) => {
    // 获取数据
    const { list, cursor } = await teacherEndTasks(params.cursor);
    // 赋值
    setDataList(_.uniqBy([...params.dataList, ...list], 'id'));
    setCursor(list.length === 20 ? cursor : null);
  });

  // 加载更多
  const loadMore = (): any => {
    if(!getEndTasks.isLoading && dataList.length !== 0) {
      getEndTasks.mutate({ dataList: dataList, cursor });
    }
  };

  return (
    <Layout paddingTop={false}>
      <div className="page-box bg-[#F7F7F7]">
        <div className={`fixed left-0 w-full box-border z-10 bg-[#F7F7F7] ${isApp() ? 'page-safe-app' : 'page-safe-other'}`}>
          <div className="px-4 pb-4 flex justify-between items-center">
            <div className="text-[24px] family-PingFangSC-PingFang font-semibold text-[#232323]">接单中心</div>
          </div>
        </div>
        <div className={`p-4 ${isApp() ? 'order-safe-app' : 'order-safe-other'}`}>
          <div className="pt-4">
            <div className="text-[16px] family-PingFangSC-PingFang font-semibold text-[#232323]">进行中</div>
            <div className="mt-3">
              <Nothing state={data && data.list?.length > 0 || false}>
                <div className="grid grid-cols-1 gap-3">
                  {
                    data && data.list.map(item => (
                      <div key={item.id} className="">
                        <TaskItem data={item}/>
                      </div>
                    ))
                  }
                </div>
              </Nothing>
            </div>
          </div>
          {
            dataList.length > 0 && <div className="mt-4">
              <div className="text-[16px] family-PingFangSC-PingFang font-semibold text-[#232323]">已结束</div>
              <div className="mt-3">
                <CustomSkeleton isLoading={getEndTasks.isLoading && cursor === ''}>
                  <div className="grid grid-cols-1 gap-3">
                    {
                      dataList.map(item => (
                        <div key={item.id} className="">
                          <TaskItem data={item}/>
                        </div>
                      ))
                    }
                  </div>
                  <InfiniteScroll loadMore={loadMore} hasMore={cursor !== null}/>
                </CustomSkeleton>
              </div>
            </div>
          }
        </div>
      </div>
    </Layout>
  )
}