import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import Layout from "@/components/Layout";
import SafeNav from "@/components/SafeNav";
import _ from "lodash";
import { getArticle } from "@/services";

export default function Agreement() {
  // 接收参数
  const { id } = useParams();
  // 配置项
  const items = [
    { id: '1809', title: '用户协议' },
    { id: '1810', title: '隐私政策' },
    { id: '3485', title: '答疑说明' },
  ];

  // 获取内容
  const { data } = useQuery([getArticle.name, id], async () => {
    // 获取数据
    const { article } = await getArticle(id);
    return article.content;
  });

  // 当前标题
  const currentTitle = useMemo(() => _.find(items, { id })?.title || '', [id]);

  return (
    <Layout paddingBottom={false}>
      <SafeNav title={`${currentTitle}`} isService={false} />
      <div className="pt-[42px] bg-[#F7F7F7]">
        {
          data && <div className="p-4 pt-0 h-full overflow-scroll">
            <div className="text-[#666] text-[0.88rem] leading-[1.31rem] break-all">
              <div dangerouslySetInnerHTML={{__html: data}} style={{lineHeight: '1.8em'}}/>
            </div>
          </div>
        }
      </div>
    </Layout>
  )
}