import { useNavigate } from "react-router-dom";
import MessageIcon from "@/components/MessageIcon";
import { isApp } from "@/utils";

import BackIcon from "@/assets/icon/back.png";

type Props = {
  title: string, // 标题
  handleCustom?: any, // 自定义事件
  isService?: boolean, // 是否显示客服
};

export default function SafeNav({ title, handleCustom, isService = true }: Props) {
  // 路由
  const navigate = useNavigate();

  return (
    <div className={`px-4 py-2.5 bg-white fixed top-0 left-0 w-full box-border flex justify-between items-center z-10 ${isApp() ? 'nav-safe-app' : 'nav-safe-other'}`}>
      <img src={BackIcon} alt="" className="w-5 h-5" onClick={() => handleCustom ? handleCustom() : navigate(-1)} />
      <div className="flex-1 ml-[34px] mr-1 text-[16px] family-PingFangSC-PingFang font-medium text-[#232323] text-center">{title}</div>
      <div className={`${isService ? '' : 'invisible'}`}>
        <MessageIcon className="w-5 h-5" />
      </div>
    </div>
  )
}