import { Toast } from "antd-mobile";
import axios from "axios";
import { getDeviceId, getToken, isIOS, addStorageListener } from ".";

// 知嘛众包自己的 api
export const answerAxios = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
});

answerAxios.interceptors.request.use((config) => {
  config.headers = {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    Authorization: `Bearer ${getToken()}`, // 优先使用 query token
    "Client-ID": getDeviceId(),
    "Client-Type": isIOS() ? 'IOS' : 'ANDROID',
    "Ditch": 'ZHIMAAPP',
    // "X-Credential-Identifier": 22, // 提交时记得注释掉
    ...config.headers,
  };

  // GET 请求，如果没有 data 值，axios 不会添加 Content-Type header
  if (config.method === "get" && !config.data) {
    config.data = {};
  }

  return config;
});

answerAxios.interceptors.response.use(
  (response) => {
    const {
      data,
      config: {
        errMsg = true, // 错误提示信息（用于覆盖接口返回的提示信息）
        successMsg = false, // 成功提示信息（用于覆盖接口返回的提示信息）
        meta = false, // 是否返回完整响应体（用于需要自己判断成功失败的情况）
      },
    } = response;
    // 返回完整响应体
    if (meta) {
      return data;
    }

    if (data.code !== 0) {
      if (errMsg) {
        Toast.show({
          content: typeof errMsg === "string" ? errMsg : data.msg || "系统繁忙",
          icon: "fail",
        });
      }
      throw new Error(data.msg);
    }

    if (successMsg) {
      Toast.show({
        content:
          typeof successMsg === "string" ? successMsg : data.msg || "操作成功",
        icon: "success",
      });
    }

    return data.data;
  },
  (err) => {
    // 登录态校验失败
    if (err?.response?.status == 401) {
      // Toast.show({ content: '请退出重新登录' });
      addStorageListener('localStorage').token = '';
      throw new Error(err);
    }
    !err?.response?.config?.errMsg
      ? Toast.show({ content: `网络异常（${err?.response?.status}）` })
      : "";
    throw new Error(err);
  }
);
