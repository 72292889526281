import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { useToggle } from "react-use";
import { Popup, CenterPopup, Input, Toast } from "antd-mobile";
import Layout from "@/components/Layout";
import SafeNav from "@/components/SafeNav";
import { useLoginInfo, useLogout } from "@/hooks/user";
import validator from "validator";
import { sendCaptcha, cancelledAccountByCaptcha } from "@/services";
import Edit from "../Edit/Index";

import RightIcon from "@/assets/icon/right.png";

// 定时器
let timer: any;

export default function Setting() {
  // 用户登录信息
  const { loginInfo } = useLoginInfo();
  // 弹窗状态
  const [visible, setVisible] = useToggle(false);
  // 表单数据
  const [formData, setFormData]: any = useState({ mobile: '', captcha: '' });
  // 倒计时
  const [time, setTime] = useState(0);
  // 默认值
  const second = 60;
  // 退出弹窗
  const [isShow, toggle] = useToggle(false);
  // 路由
  const navigate = useNavigate();
  // 退出登录
  const { logout } = useLogout();

  // 发送验证码
  const sendCaptchaByMobile = useMutation(async () => {
    if (handleCheck(true)) {
      const res: any = await sendCaptcha('cancelled_account', formData.mobile);
      res.code === 0 ? (() => {
        // 提示
        Toast.show('验证码已发送');
        // 初始化
        setTime(second);
      })() : Toast.show({ content: res.msg, icon: 'fail' });
    }
  });

  // 处理更新
  const handleUpdate = (field: string, value: string) => {
    setFormData((formData: any) => ({ ...formData, [field]: value }));
  };

  // 处理校验
  const handleCheck = (check = false) => {
    // 初始化
    let content = '', flag = false;
    // 获取数据
    const { mobile, captcha } = formData;
    // 校验
    !validator.isMobilePhone(mobile, "zh-CN") ? content = '请输入正确的手机号码'
      : !check && validator.isEmpty(captcha) ? content = '请输入验证码' : flag = true;
    !flag ? Toast.show(content) : '';
    return flag;
  };

  // 处理提交
  const handleSubmit = useMutation(async () => {
    if (handleCheck()) {
      // 解构
      const { mobile, captcha } = formData;
      // 提交
      await cancelledAccountByCaptcha({ mobile, captcha });
      logout();
      setTimeout(() => Toast.show({ content: '账号已注销，请重新登录', duration: 3000 }), 1000);
    }
  });

  // 处理退出
  const handleLogout = () => {
    logout();
    toggle(false);
  };

  // 初始化
  useEffect(() => {
    timer && clearInterval(timer);
    return () => timer && clearInterval(timer);
  }, []);

  // 定时器
  useEffect(() => {
    time === second ? timer = setInterval(() => setTime(time => --time), 1000) : time <= 0 ? clearInterval(timer) : '';
  }, [time]);

  // 监听
  useEffect(() => {
    visible ? setFormData({ mobile: loginInfo?.username, captcha: '' }) : '';
  }, [visible]);

  return (
    <Layout paddingBottom={false}>
      <SafeNav title="设置"/>
      <div className="h-full bg-[#F7F7F7]">
        <div className="pt-[42px] bg-white">
          <Edit/>
          <div className="px-4 py-3 flex justify-between items-center" onClick={() => navigate(`/privacySetting`)}>
            <div className="text-[15px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.85)]">隐私设置</div>
            <img src={RightIcon} alt="" className="ml-1.5 w-4 h-4"/>
          </div>
          <div className="half-1px-line"/>
          <div className="px-4 py-3 flex justify-between items-center" onClick={() => navigate(`/about`)}>
            <div className="text-[15px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.85)]">关于我们</div>
            <img src={RightIcon} alt="" className="ml-1.5 w-4 h-4"/>
          </div>
          <div className="half-1px-line"/>
          <div className="px-4 py-3 flex justify-between items-center" onClick={() => setVisible(true)}>
            <div className="text-[15px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.85)]">账号注销</div>
            <img src={RightIcon} alt="" className="ml-1.5 w-4 h-4"/>
          </div>
          <div className="half-1px-line"/>
          <div className="px-4 py-3 text-[15px] family-PingFangSC-PingFang text-[#FF6750]" onClick={() => toggle(true)}>退出登录</div>
          <div className="half-1px-line"/>
        </div>
        <Popup visible={visible} onMaskClick={() => setVisible(false)} className="w-full" bodyStyle={{borderTopLeftRadius: '1.25rem', borderTopRightRadius: '1.25rem'}}>
          <div className="safe-padding-bottom">
            <div className="p-4">
              <div className="text-[16px] family-PingFangSC-PingFang font-semibold text-[#232323]">账号注销</div>
              <Input type="tel" maxLength={11} value={formData.mobile} placeholder="请输入手机号" readOnly={true} className="mt-5 custom-input"/>
              <div className="relative">
                <Input type="tel" maxLength={6} value={formData.captcha} placeholder="请输入验证码" onChange={(val) => handleUpdate('captcha', val)} className="mt-3 custom-input other-input"/>
                <div className={`px-3 ${time === 0 ? 'all-withdraw-btn' : 'cancel-account-btn'}`} onClick={() => sendCaptchaByMobile.isLoading || time !== 0 ? '' : sendCaptchaByMobile.mutate()}>{`${time === 0 ? '发送验证码' : `${time}秒后可重新获取`}`}</div>
              </div>
              <div className="mt-3 text-[14px] family-PingFangSC-PingFang text-[rgba(36,36,36,0.65)] leading-6">
                <div className="text-[#FF6750]">请注意：</div>
                <div className="mt-1">账号注销后，您的账户数据和资产被会删除且无法恢复，请谨慎操作。</div>
              </div>
              <div className={`mt-10 submit-btn ${handleSubmit.isLoading ? 'opacity-70' : ''}`} onClick={() => handleSubmit.isLoading ? '' : handleSubmit.mutate()}>确定</div>
            </div>
          </div>
        </Popup>
        <CenterPopup visible={isShow} onMaskClick={() => toggle(false)} className="w-full" style={{'--border-radius': '1rem', '--min-width': '80vw'}}>
          <div className="p-4">
            <div className="text-[18px] family-PingFangSC-PingFang font-semibold text-[#232323] text-center">提示</div>
            <div className="mt-4 text-[15px] family-PingFangSC-PingFang text-[rgba(36,36,36,0.65)] text-center">确定退出当前账号吗？</div>
            <div className={`mt-10 submit-btn`} onClick={handleLogout}>确定</div>
          </div>
        </CenterPopup>
      </div>
    </Layout>
  )
}