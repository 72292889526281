import { answerAxios } from "@/utils/axios";

// 创建教师信息
export const addTeacher = (data: teachInfo) => answerAxios.post(`/api_go/may_auth/teacher_answer/add_teacher`, data, { successMsg: '编辑成功' });

// 编辑教师信息
export const editTeacher = (data: { nickname?: string, headimgurl?: string }) => answerAxios.post(`/api_go/may_auth/teacher_answer/update_teacher`, data, { successMsg: '编辑成功' });

// 新增、编辑提现账户
export const saveWithdrawAccount = (params: { id: number | string, type: string | number, real_name: string, account_number: string, mobile?: string}, successMsg = true) => answerAxios.post(`/api/need_auth/withdraw/save_withdraw_account`, params, { successMsg });

// 获取可提现金额
export const getCanWithdrawMoney = (): Promise<{ can_withdraw_money: number }> => answerAxios.get(`/api/need_auth/withdraw/get_can_withdraw_money`, {});

// 发起提现
export const withdrawMoney = (params: { money: number, type: string | number, real_name: string, account_number: string, mobile: string }) => answerAxios.post(`/api/need_auth/withdraw/withdraw_money`, params, { meta: true });

// 绑定第三方平台 - code
export const bindSnsByCode = (params: { channel: string, code: string }) => answerAxios.post(`/api/need_auth/sns/bind_sns_by_code?code=${params.code}`, params, {});

// 解绑第三方平台
export const unbindSns = (channel: string) => answerAxios.post(`/api/need_auth/sns/unbind_sns/${channel}`, {}, {});

// 获取文章详情
export const getArticle = (article_id: number | string | undefined): Promise<{ article: Article }> => answerAxios.get(`/api/may_auth/article/get_article/${article_id}`, {});

// 注销用户
export const cancelledAccountByCaptcha = (params: { mobile: string, captcha: string }) => answerAxios.post(`/api/need_auth/user/cancelled_account_by_captcha`, params, {});

// 发送验证码
export const sendCaptcha = (captcha_type: string, mobile: string, captchaToken?: string) => answerAxios.post(`/api/may_auth/sms/send_captcha/${captcha_type}/${mobile}`, { ali_captcha: captchaToken }, { meta: true });

// 获取提现记录
export const getWithdrawRecord = (params: { cursor?: string | null }): Promise<{ record: { data: WithdrawRecord[], next_cursor: string } }> => answerAxios.get(`/api/need_auth/withdraw/get_withdraw_record`, { params });