import { useState } from "react";
import queryString from "query-string";
import { v4 as uuidv4 } from "uuid";
import { Modal } from "antd-mobile";
import { safeArea } from "@/apicloud/api";
import VConsole from "vconsole";

// 获取设备ID
export function getDeviceId() {
  // 优先取 url 上的
  const query = queryString.parse(location.search);
  const urlDeviceId = (query.deviceId as string) || (query.d as string);
  if (urlDeviceId && typeof urlDeviceId === "string") {
    return urlDeviceId;
  }

  // 取 localStorage
  let localDeviceId = localStorage.getItem("client_id");
  if (localDeviceId) {
    return localDeviceId;
  }

  // 生成新的并保存到 localStorage
  localDeviceId = uuidv4();
  localStorage.setItem("client_id", localDeviceId);

  return localDeviceId;
}

// 获取 token
export function getToken() {
  // 优先取 url 上的
  const query = queryString.parse(location.search);
  const urlToken = query.token;
  if (urlToken && typeof urlToken === "string") {
    return urlToken;
  }

  // 取 localStorage
  let localToken = localStorage.getItem("token") || "";
  return localToken;
}

// 是否 ios
export function isIOS() {
  return /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent);
}

// 是否 android
export function isAndroid() {
  return /(Android)/i.test(navigator.userAgent);
}

// 是否 在APP 内
export function isApp() {
  return /APICloud/i.test(window.navigator.userAgent);
}

// 打开新的窗口（如果 URL 以 http/https 开头，打开 web 页面，否则打开应用内页面）
export function openWin(url: string, name?: string) {
  isApp() ? window.api.openWin({ name, url }) : window.open(url);
}

// 是否有权限（单个）
export const hasOnePermission = (permission: string): any => {
  const res = window.api.hasPermission({
    list: [permission],
  });
  return res[0];
};

// 申请权限说明弹窗
export const showPermissionModal = (title: string, content: string) => {
  Modal.show({
    title,
    content,
    className: "permission-modal",
    bodyStyle: {
      top: 0,
      position: "fixed",
    },
  });
};

// 关闭权限说明弹窗
export const hidePermissionModal = () => {
  Modal.clear();
};

export function getFileExt(fileName: string) {
  return (fileName.split(".").pop() || "").toLowerCase();
}

// 初始化样式
export const initTheme = () => {
  // 设置 css 安全区变量
  document.documentElement.style.setProperty(
    "--safe-area-inset-top",
    safeArea().top + "px"
  );
  document.documentElement.style.setProperty(
    "--safe-area-inset-bottom",
    safeArea().bottom + "px"
  );
}

/**
 * 进入 debug 模式
 * 进入条件：触发点击事件 10 次
 */
export function useDebugMode() {
  const [time, setTimes] = useState(0)

  const click = () => {
    if (time === 10) {
      new VConsole()
      localStorage.setItem('debug', 'true')
    }
    setTimes(time + 1)
  }

  return { click }
}

/**
 * 使用 Proxy 监听 storage 变化
 * @param storageType 类型
 */
export const addStorageListener = (storageType: string) => {
  const storage = storageType === 'localStorage' ? localStorage : sessionStorage;
  return new Proxy(storage, {
    get: (target, key: string) => target.getItem(key),
    set: (target, key: string, value) =>  {
      const storageEvent = new StorageEvent('storage', {
        key,
        newValue: value,
        oldValue: target.getItem(key),
        storageArea: storage,
        url: window.location.href,
      });
      target.setItem(key, value);
      window.dispatchEvent(storageEvent);
      return true;
    }
  });
}