import { useMemo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { useToggle } from "react-use";
import Layout from "@/components/Layout";
import { Avatar, Popup, Input, Toast, CenterPopup, PullToRefresh } from "antd-mobile";
import { useRecoilState } from "recoil";
import { CanWithdrawMoney, LoginModalOpenState } from "@/models/user";
import { PHASE_INFO } from "@/constants/user";
import { useLoginInfo, useWithdrawAccount } from "@/hooks/user";
import validator from "validator";
import { getCanWithdrawMoney, withdrawMoney } from "@/services";
import { useGoService } from "@/hooks/common";
import { QuestionCircleFill } from "antd-mobile-icons";
import _ from "lodash";
import { useScan } from "@/hooks/common";

import MeBg from "@/assets/me-bg.png";
import MeRight from "@/assets/icon/me-right.png";
import WithdrawBg from "@/assets/withdraw-bg.png";
import MoneyIcon from "@/assets/icon/money.png";
import DefaultAvatar from "@/assets/default-avatar.png";
import AliPay from "@/assets/icon/ali-pay.png";
import WxPay from "@/assets/icon/wx-pay.png";
import ActiveIcon from "@/assets/icon/active.png";
import SettingIcon from "@/assets/icon/setting.png";
import MessageIcon from "@/components/MessageIcon";
import LevelFirst from "@/assets/icon/level-first.png";
import LevelSecond from "@/assets/icon/level-second.png";
import LevelThird from "@/assets/icon/level-third.png";
import LevelFourth from "@/assets/icon/level-fourth.png";
import LevelFifth from "@/assets/icon/level-fifth.png";
import LevelSixth from "@/assets/icon/level-sixth.png";
import ScanIcon from "@/assets/icon/scan.png";

export default function Me() {
  // 用户登录信息
  const { loginInfo, refresh } = useLoginInfo();
  // 用户提现账户信息
  const { withdrawAccount, getAccount } = useWithdrawAccount();
  // 绑定弹窗
  const [bind, setBind] = useToggle(false);
  // 弹窗状态
  const [visible, setVisible] = useToggle(false);
  // 提现信息
  const [formData, setFormData]: any = useState({});
  // 可提现金额
  const [canWithdrawMoney, setCanWithdrawMoney] = useRecoilState(CanWithdrawMoney);
  // 提现联系客服
  const go = useGoService({ type: 'withdraw' });
  // 路由
  const navigate = useNavigate();
  // 提示弹窗
  const [hint, setHint] = useToggle(false);
  // 提示信息
  const [msg, setMsg] = useState('');
  // 是否展示
  const [open, setOpen] = useRecoilState(LoginModalOpenState);
  // 扫码
  const { handleOpenScan } = useScan();

  // 擅长科目
  const goodSubjects = useMemo(() => loginInfo?.beat_subjects.map(item => `${PHASE_INFO[item.period]}${item.subject}`).join('、'), [loginInfo?.beat_subjects])
  // 当前等级
  const currentLevel = useMemo(() => {
    const items = [
      { level: 1, image: LevelFirst },
      { level: 2, image: LevelSecond },
      { level: 3, image: LevelThird },
      { level: 4, image: LevelFourth },
      { level: 5, image: LevelFifth },
      { level: 6, image: LevelSixth },
    ];
    return _.find(items, { level: loginInfo?.level })?.image;
  },[loginInfo?.level]);

  // 处理校验
  const handleWithdraw = () => {
    withdrawAccount.length === 0 ? setBind(true) : loginInfo && loginInfo?.credit <= 0 ? (() => {
      setMsg(`您存在违规操作，信誉分已降低为 ${loginInfo?.credit} 分且无法提现，如有问题，可联系客服。`);
      setHint(true);
    })() : (() => {
      setFormData({ money: '', real_name: withdrawAccount.length > 0 ? withdrawAccount[0].real_name : '', payment: withdrawAccount[0].account_type });
      setVisible(true);
    })();
  };

  // 获取可提现金额
  const getMoney = async () => {
    // 获取数据
    const { can_withdraw_money } = await getCanWithdrawMoney();
    // 赋值
    setCanWithdrawMoney(can_withdraw_money);
  };

  // 处理全部提现
  const handleAllWithdraw = () => canWithdrawMoney - 1 >= 0 ? setFormData((formData: any) => ({ ...formData, money: String(canWithdrawMoney) })) : Toast.show('可提现金额不足，无法提现');

  // 处理更新
  const handleUpdate = (field: string, value: string) => {
    setFormData((formData: any) => ({ ...formData, [field]: value }));
  };

  // 处理校验
  const handleCheck = () => {
    // 初始化
    let content = '', flag = false;
    // 获取数据
    const { money, real_name, payment } = formData;
    // 校验
    const regex = /(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,2})?$)|(?:^(?:0)$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/;
    !validator.isFloat(money) ? content = '请输入提现金额'
      : canWithdrawMoney - 1 < 0 || canWithdrawMoney - money < 0 ? content = '可提现金额不足，无法提现'
        : !regex.test(money) ? content = '请输入正确金额格式，可保留两位小数'
          : money - 1 < 0 ? content = '提现金额最小为1'
            : !payment ? content = '请选择提现方式'
              : validator.isEmpty(real_name) ? content = '请输入真实姓名' : flag = true;
    !flag ? Toast.show(content) : '';
    return flag;
  };

  // 处理提交
  const handleSubmit = useMutation(async () => {
    if (handleCheck()) {
      // 解构
      const { money, real_name, payment } = formData;
      const { account_number, mobile } = withdrawAccount.filter(item => item.account_type === payment)[0];
      const data: any = await withdrawMoney({ money, type: payment, real_name, account_number, mobile });
      data.code !== 0 ? (() => {
        setMsg(data.msg);
        setHint(true);
      })() : (async () => {
        Toast.show({ content: '操作成功', icon: 'success' });
        // 更新可提现金额
        await getMoney();
      })();
      setVisible(false);
    }
  });

  // 处理绑定
  const handleBind = () => {
    navigate(`/setting`);
    setBind(false);
  };

  // 处理客服
  const handleService = () => {
    go();
    setHint(false);
  };

  // 初始化
  useEffect(() => {
    !localStorage.getItem('token') ? setOpen(true) : refresh();
  }, []);

  // 监听
  useEffect(() => {
    !open && loginInfo ? (() => {
      getMoney();
      getAccount();
    })() : navigate('/');
  }, [open]);

  return (
    <Layout paddingTop={false}>
      <div className="page-nav" style={{backgroundImage: `url(${MeBg})`, backgroundSize: '100% 17.5rem', backgroundRepeat: 'no-repeat'}}>
        <PullToRefresh onRefresh={refresh}>
          <div className="px-4 pt-4 flex justify-between items-center">
            <div className="text-[24px] family-PingFangSC-PingFang font-semibold text-[#232323]">我的</div>
            <div className="flex">
              <div className="flex items-center" onClick={handleOpenScan}>
                <img src={ScanIcon} alt="" className="w-6 h-6"/>
                <div className="ml-1.5 text-[12px] family-PingFangSC-PingFang text-[rgba(36,36,36,0.65)]">扫码</div>
              </div>
              <div className="ml-3">
                <MessageIcon/>
              </div>
              <div className="flex items-center ml-3" onClick={() => navigate(`/setting`)}>
                <img src={SettingIcon} alt="" className="w-6 h-6"/>
                <div className="ml-1.5 text-[12px] family-PingFangSC-PingFang text-[rgba(36,36,36,0.65)]">设置</div>
              </div>
            </div>
          </div>
          <div className="px-4 mt-10 flex items-center">
            <Avatar src={loginInfo?.avatar || DefaultAvatar} style={{'--size': '4.125rem', '--border-radius': '2.0625rem', 'border': '1px solid #232323'}}/>
            <div className="ml-4 flex-1">
              <div className="flex items-center" onClick={() => navigate(`/setting`)}>
                {loginInfo?.level !== 0 && <img src={currentLevel} alt="" className="w-[50px] h-[20px] mr-2"/>}
                <div className="text-[18px] family-PingFangSC-PingFang font-semibold text-[#232323]">{loginInfo?.nickname || '知嘛用户'}</div>
                {/*<img src={MeRight} alt="" className="ml-1.5 w-[18px] h-[18px]"/>*/}
              </div>
              <div className="mt-1.5 text-[12px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.45)]">{goodSubjects}</div>
            </div>
          </div>
          <div className="px-5 mt-8 grid grid-cols-4 gap-4">
            <div className="flex flex-col items-center">
              <div className="text-[20px] family-D-DIN-Bold text-[#232323]">{loginInfo?.solve_num || 0}</div>
              <div className="mt-1 text-[13px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.65)]">解答题数</div>
            </div>
            <div className="flex flex-col items-center">
              <div className="text-[20px] family-D-DIN-Bold text-[#232323]">{loginInfo?.total_gain || 0}</div>
              <div className="mt-1 text-[13px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.65)]">累计收益</div>
            </div>
            <div className="flex flex-col items-center">
              <div className="text-[20px] family-D-DIN-Bold text-[#232323]">{loginInfo?.positive_eval_rate || '0.00'}%</div>
              <div className="mt-1 text-[13px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.65)]">好评率</div>
            </div>
            <div className="flex flex-col items-center">
              <div className="text-[20px] family-D-DIN-Bold text-[#232323]">{loginInfo?.credit || 0}</div>
              <div className="mt-1 text-[13px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.65)]">信誉分</div>
            </div>
          </div>
          <div className="mt-6 ml-4 mr-3.5 flex justify-between items-center pl-4 pr-2"
               style={{height: '4.75rem', backgroundImage: `url(${WithdrawBg})`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat'}}>
            <div className="mt-2 flex-1">
              <div className="flex items-center">
                <img src={MoneyIcon} alt="" className="w-4 h-4"/>
                <div className="ml-1.5 text-[14px] family-PingFangSC-PingFang font-semibold text-[#232323]">可提现金额：{canWithdrawMoney}元</div>
              </div>
              <div className="mt-1 flex items-center" onClick={() => Toast.show({ content: '完成解答后，奖励将在 24 小时后到账，到账后随时可以提现' })}>
                <div className="text-[12px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.45)]">待到账金额：{loginInfo?.pending_payment_amount || 0}元</div>
                <QuestionCircleFill color="var(--adm-color-danger)" className="ml-1.5 text-[14px]" />
              </div>
            </div>
            <div className="withdraw-btn text-[13px] family-PingFangSC-PingFang text-white" onClick={handleWithdraw}>立即提现</div>
          </div>
          <div className="px-6 mt-3 flex items-center justify-end" onClick={() => navigate(`/withdrawRecord`)}>
            <div className="text-[13px] family-PingFangSC-PingFang ">提现记录</div>
            <img src={MeRight} alt="" className="ml-1.5 w-[18px] h-[18px]"/>
          </div>
        </PullToRefresh>
      </div>
      <CenterPopup visible={bind} onMaskClick={() => setBind(false)} className="w-full" style={{'--border-radius': '1rem', '--min-width': '80vw'}}>
        <div className="p-4">
          <div className="text-[18px] family-PingFangSC-PingFang font-semibold text-[#232323] text-center">提示</div>
          <div className="mt-4 text-[15px] family-PingFangSC-PingFang text-[rgba(36,36,36,0.65)] text-center">未绑定提现账号</div>
          <div className={`mt-10 submit-btn`} onClick={handleBind}>去绑定</div>
        </div>
      </CenterPopup>
      <Popup visible={visible} onMaskClick={() => setVisible(false)} className="w-full" bodyStyle={{borderTopLeftRadius: '1.25rem', borderTopRightRadius: '1.25rem'}}>
        <div className="safe-padding-bottom">
          <div className="p-4">
            <div className="text-[16px] family-PingFangSC-PingFang font-semibold text-[#232323]">可提现金额：{canWithdrawMoney}元</div>
            <div className="relative">
              <Input inputMode="numeric" value={formData.money} placeholder="最小提现金额为1元" onChange={(val) => handleUpdate('money', val)} className="mt-5 custom-input other-input"/>
              <div className="px-3 all-withdraw-btn" onClick={handleAllWithdraw}>全部提现</div>
            </div>
            <Input value={formData.real_name} placeholder="请输入真实姓名" onChange={(val) => handleUpdate('real_name', val)} readOnly={formData.payment === 2} className="mt-3 custom-input"/>
            <div className="mt-4 text-[16px] family-PingFangSC-PingFang font-semibold text-[#232323]">提现方式</div>
            <div className="pt-1">
              {
                withdrawAccount.map((item, index: number) => (
                  <div key={index} className="mt-3" onClick={() => setFormData((formData: any) => ({ ...formData, real_name: item.account_type === 2 ? item.real_name : '', payment: item.account_type }))}>
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <img src={item.account_type === 2 ? AliPay : WxPay} alt="" className="w-9 h-9"/>
                        <div className="ml-3 text-[14px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.85)]">{item.account_type === 2 ? '支付宝' : '微信'}</div>
                      </div>
                      <img src={ActiveIcon} alt="" className={`w-[18px] h-[18px] ${formData.payment === item.account_type ? '' : 'invisible'}`}/>
                    </div>
                    {index === 0 && <div className="pt-3 mb-3 half-1px-border"/>}
                  </div>
                ))
              }
            </div>
            <div className={`mt-6 submit-btn ${handleSubmit.isLoading ? 'opacity-70' : ''}`} onClick={() => handleSubmit.isLoading ? '' : handleSubmit.mutate()}>立即提现</div>
          </div>
        </div>
      </Popup>
      <CenterPopup visible={hint} onMaskClick={() => setHint(false)} className="w-full" style={{'--border-radius': '1rem', '--min-width': '80vw'}}>
        <div className="p-4">
          <div className="text-[18px] family-PingFangSC-PingFang font-semibold text-[#232323] text-center">提示</div>
          <div className="mt-4 text-[15px] family-PingFangSC-PingFang text-[rgba(36,36,36,0.65)] text-center">{msg}</div>
          <div className={`mt-10 submit-btn`} onClick={handleService}>确定</div>
        </div>
      </CenterPopup>
    </Layout>
  )
}