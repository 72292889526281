import { useNavigate } from "react-router-dom";
import { LoginInfo, WithdrawAccount, IsSubscribe } from "@/models/user";
import { answerAxios } from "@/utils/axios";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { LoginModalOpenState } from "@/models/user";
import _ from "lodash";
import * as Sentry from "@sentry/react";

export function useLoginInfo(): { loginInfo: LoginDetail | null, isLogined: boolean; refresh: () => Promise<void> } {
  // 用户登录信息
  const [loginInfo, setLoginInfo] = useRecoilState(LoginInfo);
  // 登录弹窗
  const [, setLoginModalOpenState] = useRecoilState(LoginModalOpenState);
  // 是否订阅
  const [, setIsSubscribe] = useRecoilState(IsSubscribe);

  // 获取用户数据
  const refresh = async () => {
    // 获取数据
    const { data, code } = await answerAxios.get<any, { data: any, code: number }>(
      `/api_go/may_auth/teacher_answer/teacher_info`,
      { meta: true }
    );
    code === 0 ? (() => {
      setLoginInfo(data);
      setIsSubscribe(_.includes(localStorage.getItem('user_subscribe'), data.id));
    })() : setLoginModalOpenState(true);
  };

  return { isLogined: !!loginInfo, loginInfo, refresh };
}

// 获取用户登录信息
export function useQueryLoginInfo() {
  // 用户登录信息
  const [, setLoginInfo] = useRecoilState(LoginInfo);
  // 登录弹窗
  const [, setLoginModalOpenState] = useRecoilState(LoginModalOpenState);
  // 是否订阅
  const [, setIsSubscribe] = useRecoilState(IsSubscribe);

  // 获取用户数据
  const { isLoading } = useQuery("LoginInfo", () => answerAxios.get<any, { data: any, code: number }>(
    `/api_go/may_auth/teacher_answer/teacher_info`,
    { meta: true, errMsg: false },
  ), {
    retry: false,
    async onSuccess(res) {
      res?.code === 0 ? (() => {
        setLoginInfo(res.data);
        setIsSubscribe(_.includes(localStorage.getItem('user_subscribe'), res.data.id));
        Sentry.setUser({ id: res.data.id, username: res.data.nickname });
      })() : setLoginModalOpenState(true);
    }
  });

  return { loading: isLoading };
}

// 获取用户提现账户
export const useWithdrawAccount = () => {
  // 用户提现账户信息
  const [withdrawAccount, setWithdrawAccount] = useRecoilState(WithdrawAccount);

  // 获取用户数据
  const getAccount = async () => {
    // 获取数据
    const { account_list } = await answerAxios.get<any, { account_list: AccountList[] }>(
      `/api/need_auth/withdraw/get_withdraw_account_list`, { params: { type: '2' }},
    );
    // 赋值
    setWithdrawAccount(account_list);
    return { account_list };
  };

  return { withdrawAccount, getAccount };
};

// 用户退出登录
export const useLogout = () => {
  // 用户登录信息
  const [, setLoginInfo] = useRecoilState(LoginInfo);
  // 登录弹窗
  const [, setLoginModalOpenState] = useRecoilState(LoginModalOpenState);
  // 路由
  const navigate = useNavigate();

  const logout = () => {
    setLoginInfo(null);
    setLoginModalOpenState(true);
    localStorage.setItem('token', '');
    navigate(`/`);
  };

  return { logout };
};

