import { atom } from "recoil";

// 用户登录信息
export const LoginInfo = atom<LoginDetail | null>({
  key: 'LoginInfo',
  default: null,
});

// 登录弹窗状态
export const LoginModalOpenState = atom<boolean>({
  key: 'LoginModalOpenState',
  default: false,
});

// 擅长科目
export const GoodSubject = atom({
  key: 'GoodSubject',
  default: [
    { phase: '1', name: '小学', subjects: ['语文', '数学', '英语'] },
    { phase: '2', name: '初中', subjects: ['语文', '数学', '英语', '物理', '化学', '生物', '道德与法治', '历史', '地理'] },
    { phase: '3', name: '高中', subjects: ['语文', '数学', '英语', '物理', '化学', '生物', '政治', '历史', '地理'] },
  ],
});

// 用户提现账户信息
export const WithdrawAccount = atom<AccountList[] | []>({
  key: 'WithdrawAccount',
  default: [],
});

// 用户可提现金额
export const CanWithdrawMoney = atom({
  key: 'CanWithdrawMoney',
  default: 0,
});

// 是否订阅
export const IsSubscribe = atom({
  key: 'IsSubscribe',
  default: true,
});

// 是否跳过
export const IsPass = atom({
  key: 'IsPass',
  default: false,
});
