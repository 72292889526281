import "react-app-polyfill/stable";
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";
import VConsole from "vconsole";
import "./util.css";
import "./index.css";
import AppContainer from "@/components/AppContainer";
import React from "react";
import { initTheme, isApp } from "@/utils";
import { initJPushModule } from "./hooks/common";
import { initSentry } from "./utils/sentry";

import Home from "./pages/Home/Index";
import Order from "./pages/Order/Index";
import Me from "./pages/Me/Index";
import Setting from "./pages/Setting/Index";
import About from "./pages/About/Index";
import Agreement from "./pages/About/Agreement";
import Report from "./pages/About/Report";
import Contact from "./pages/About/Contact";
import WithdrawRecord from "./pages/WithdrawRecord/Index";
import Question from "./pages/Question/Index";
import PrivacySetting from "./pages/PrivacySetting/Index";
import PersonalInventory from "./pages/PrivacySetting/PersonalInventory";
import OtherInventory from "./pages/PrivacySetting/OtherInventory";
import DeviceProperties from "./pages/PrivacySetting/DeviceProperties";

// vConsole
if (import.meta.env.MODE === "development" || localStorage.getItem("debug") === "true") {
  new VConsole();
}

initSentry()

const Page = ({ component }: { component: React.ReactNode }) => {
  return <React.Suspense fallback={<></>}>{component}</React.Suspense>;
};

function render() {
  ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <AppContainer custom={false}>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Page component={<Home />} />} />
          <Route path="/order" element={<Page component={<Order />} />} />
          <Route path="/me" element={<Page component={<Me />} />} />
          <Route path="/setting" element={<Page component={<Setting />} />} />
          <Route path="/about" element={<Page component={<About />} />} />
          <Route path="/agreement/:id" element={<Page component={<Agreement />} />} />
          <Route path="/report" element={<Page component={<Report />} />} />
          <Route path="/contact" element={<Page component={<Contact />} />} />
          <Route path="/withdrawRecord" element={<Page component={<WithdrawRecord />} />} />
          <Route path="/question" element={<Page component={<Question />} />} />
          <Route path="/privacySetting" element={<Page component={<PrivacySetting />} />} />
          <Route path="/personalInventory" element={<Page component={<PersonalInventory />} />} />
          <Route path="/otherInventory" element={<Page component={<OtherInventory />} />} />
          <Route path="/deviceProperties" element={<Page component={<DeviceProperties />} />} />
        </Routes>
      </HashRouter>
    </AppContainer>
  );
}

if (isApp()) {
  window.apiready = function () {
    window.modules = {
      jpushVip: window.api.require("jpushVip"),
      // wxPlus: window.api.require('wxPlus'),
      FNScanner: window.api.require("FNScanner"),
    };
    initTheme();
    initJPushModule();
    render();
  };
} else {
  render();
}
