import { useState, useMemo } from "react";
import { useMutation } from "react-query";
import { useToggle } from "react-use";
import { useRecoilState } from "recoil";
import { Popup, Tabs, Input, Toast, CenterPopup } from "antd-mobile";
import Layout from "@/components/Layout";
import { useLoginInfo } from "@/hooks/user";
import { isApp } from "@/utils";
import validator from "validator";
import Nothing from "@/components/Nothing";
import { useScan } from "@/hooks/common";
import _ from "lodash";
import copy from "copy-to-clipboard";
import { addAuthReview, getAuthReview } from "@/services";
import { AUDIT_STATUS_TEXT } from "@/constants/common";
import { IsPass } from "@/models/user";

import NoPermission from "@/assets/no-permission.png";
import PromptIcon from "@/assets/icon/prompt.png";
import HintIcon from "@/assets/icon/hint.png";

export default function Question() {
  // 用户登录信息
  const { loginInfo, refresh } = useLoginInfo();
  // 弹窗状态
  const [visible, setVisible] = useToggle(false);
  // 表单数据
  const [formData, setFormData]: any = useState({ identity: '', info: '', province: '', score: '', channel: '' });
  // 扫码
  const { handleOpenScan } = useScan();
  // 配置项
  const items = [
    { id: '1', type: 'split_auth', name: '拆题', url: 'https://zhimaapp.timasky.com/pc.html' },
    { id: '2', type: 'knowledge_auth', name: '知识点', url: 'https://zhimaapp.timasky.com/pc.html' },
  ];
  // 当前选中
  const [activeKey, setActiveKey] = useState('1');
  // 弹窗状态
  const [isShow, toggle] = useState(false);
  // 拒绝原因
  const [reason, setReason] = useState('');
  // 是否跳过
  const [isPass, setPass] = useRecoilState(IsPass);

  // 测试老师Id
  const teacherIds = [23, 16];

  // 当前数据
  const currentData = useMemo(() => {
    const obj: any = _.find(items, { id: activeKey });
    return { ...obj, status: obj?.type === 'split_auth' ? loginInfo?.split_auth || 0 : 0 }
  }, [activeKey, loginInfo?.split_auth]);

  // 处理更新
  const handleUpdate = (field: string, value: string) => {
    setFormData((formData: any) => ({ ...formData, [field]: value }));
  };

  // 处理校验
  const handleCheck = () => {
    // 初始化
    let content = '', flag = false;
    // 获取数据
    const { identity } = formData;
    // 校验
    validator.isEmpty(identity) ? content = '请输入身份' : flag = true;
    !flag ? Toast.show(content) : '';
    return flag;
  };

  // 处理提交
  const handleSubmit = useMutation(async () => {
    if(handleCheck()) {
      // 解构
      const { identity, info, province, score, channel } = formData;
      const content = activeKey !== '2' ? { info, channel } : { info, province, score, channel };
      const params = { auth_type: currentData?.type, identity, content };
      // 提交审核
      await addAuthReview(params);
      refresh();
      setVisible(false);
    }
  });

  // 处理复制
  const handleCopy = (value: string, text: string) => {
    if (value) {
      copy(value);
      Toast.show({ content: `${text}复制成功`, icon: 'success' });
    }
  };

  // 处理查看
  const handlePreview = useMutation(async () => {
    // 获取数据
    const res: any = await getAuthReview(currentData?.type);
    // 赋值
    setReason(res?.reject_reason);
    toggle(true);
  });

  // 处理操作
  const handleOperate = () => {
    _.includes(teacherIds, loginInfo?.id) ? setVisible(true) : Toast.show({ content: `近期上线，敬请期待` });
  };

  return (
    <Layout paddingTop={false}>
      <div className="page-box bg-[#F7F7F7]">
        <div className={`fixed left-0 w-full box-border z-10 bg-[#F7F7F7] ${isApp() ? 'page-safe-app' : 'page-safe-other'}`}>
          <div className="px-4 pb-4 flex justify-between items-center">
            <div className="text-[24px] family-PingFangSC-PingFang font-semibold text-[#232323]">题库大厅</div>
          </div>
        </div>
        <div className={`p-1 ${isApp() ? 'order-safe-app' : 'order-safe-other'}`}>
          <div className="pt-4">
            <Tabs stretch={false} activeLineMode="fixed" className="question-tab" activeKey={activeKey} onChange={(key) => setActiveKey(key)} style={{
              "--fixed-active-line-width": "1rem", '--title-font-size': '1rem', '--content-padding': '0', '--active-line-color': '#242424'
            }}>
              {
                items.map((item: any) => (
                  <Tabs.Tab title={item?.name} key={item?.id}/>
                ))
              }
            </Tabs>
            <div className="px-3">
              {
                currentData?.status !== 2 ? <div className="">
                  <Nothing state={false} marginTop="mt-20" image={NoPermission} description={AUDIT_STATUS_TEXT[currentData?.status]} children={null}/>
                  {
                    loginInfo && (currentData?.status === 0 || currentData?.status === 3) && <div className="mt-10 px-10">
                      <div className="submit-btn" onClick={() => loginInfo?.beat_subjects.length === 0 && isPass ? setPass(false) : handleOperate()}>申请开通此功能</div>
                    </div>
                  }
                </div> : <div className="mt-4 p-7 bg-white rounded-xl flex flex-col items-center">
                  <div className="flex items-center">
                    <img src={PromptIcon} alt="" className="w-3.5 h-3.5"/>
                    <div className="ml-1 text-[15px] family-PingFangSC-PingFang font-medium text-[#232323]">温馨提示</div>
                  </div>
                  <div className="text-[14px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.65)] text-center break-all">
                    <div className="mt-3">请移至PC端进行{currentData?.name}任务</div>
                    <div className="mt-1.5">{currentData?.url}</div>
                  </div>
                  <div className="mt-4 grid grid-cols-2 gap-4">
                    <div className="question-left-btn" onClick={() => handleCopy(currentData?.url, '链接')}>复制链接</div>
                    <div className="question-right-btn" onClick={handleOpenScan}>扫码登录</div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <Popup visible={visible} onMaskClick={() => setVisible(false)} className="w-full" bodyStyle={{borderTopLeftRadius: '1.25rem', borderTopRightRadius: '1.25rem'}}>
        <div className="safe-padding-bottom">
          <div className="p-4 max-h-[85vh] overflow-y-scroll">
            <div className="flex justify-between items-center">
              <div className="text-[16px] family-PingFangSC-PingFang font-semibold text-[#232323]">填写审核信息</div>
              {
                currentData?.status === 3 && <div className="flex items-center hint-btn" onClick={() => handlePreview.isLoading ? '' : handlePreview.mutate()}>
                  <div className="text-[13px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.65)]">拒绝原因</div>
                  <img src={HintIcon} alt="" className="ml-1 w-3 h-3"/>
                </div>
              }
            </div>
            <div className="mt-4 pb-3 half-1px-border">
              <div className="text-[14px] family-PingFangSC-PingFang">
                <span className="text-[#FF5C4E]">*</span>
                <span className="text-[rgba(35,35,35,0.65)]">当前身份</span>
              </div>
              <Input placeholder="大学生/在职老师/教育机构老师" onChange={(val) => handleUpdate('identity', val)} className="mt-3 custom-input"/>
            </div>
            <div className="mt-3 text-[14px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.65)]">如果您是大学生，当前年级和专业？</div>
            <Input placeholder="请输入年级和专业" onChange={(val) => handleUpdate('info', val)} className="mt-3 custom-input"/>
            {
              activeKey === '2' && <div className="">
                <div className="mt-3 text-[14px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.65)]">参加高考的省份</div>
                <Input placeholder="请输入省份名称" onChange={(val) => handleUpdate('province', val)} className="mt-3 custom-input"/>
                <div className="mt-3 text-[14px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.65)]">高考分数</div>
                <Input type="tel" maxLength={3} placeholder="请输入高考分数" onChange={(val) => handleUpdate('score', val)} className="mt-3 custom-input"/>
              </div>
            }
            <div className="mt-3 text-[14px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.65)]">如何知道知嘛众包平台的？</div>
            <Input placeholder="请输入渠道" onChange={(val) => handleUpdate('channel', val)} className="mt-3 custom-input"/>
            <div className={`mt-10 submit-btn ${handleSubmit.isLoading ? 'opacity-70' : ''}`} onClick={() => handleSubmit.isLoading ? '' : handleSubmit.mutate()}>提交</div>
          </div>
        </div>
      </Popup>
      <CenterPopup visible={isShow} onMaskClick={() => toggle(false)} className="w-full" style={{'--border-radius': '1rem', '--min-width': '80vw', '--z-index': '1001'}}>
        <div className="p-4">
          <div className="text-[18px] family-PingFangSC-PingFang font-semibold text-[#232323] text-center">拒绝原因</div>
          <div className="mt-4 text-[15px] family-PingFangSC-PingFang text-[rgba(36,36,36,0.65)] text-center">{reason}</div>
          <div className={`mt-10 submit-btn`} onClick={() => toggle(false)}>我知道了</div>
        </div>
      </CenterPopup>
    </Layout>
  )
}