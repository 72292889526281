import { useState, useEffect, useRef } from "react";
import { useMutation } from "react-query";
import { useRecoilState, useRecoilValue } from "recoil";
import { useToggle } from "react-use";
import { Avatar, Popup, Input, Toast, CenterPopup, ActionSheet } from "antd-mobile";
import { useLoginInfo, useWithdrawAccount } from "@/hooks/user";
import { PHASE_INFO } from "@/constants/user";
import { uploadOss, uploadOssByLocalPath } from "@/utils/upload";
import { editTeacher, saveWithdrawAccount, bindSnsByCode, unbindSns } from "@/services";
import validator from "validator";
import { isApp } from "@/utils";
import { isWxInstalled, wxLogin } from "@/apicloud/modules/wxPlus";
import _ from "lodash";
import { ImageAction } from "@/models/dict";
import { getPicture } from "@/apicloud/api";
import { IsPass } from "@/models/user";

import DefaultAvatar from "@/assets/default-avatar.png";
import RightIcon from "@/assets/icon/right.png";

export default function Edit() {
  // 用户登录信息
  const { loginInfo, refresh } = useLoginInfo();
  // 弹窗状态
  const [visible, setVisible] = useToggle(false);
  // 上传文件
  const uploadFileRef = useRef<HTMLElement | null>(null);
  // 昵称
  const [nickName, setNickName] = useState('');
  // 用户提现账户信息
  const { getAccount } = useWithdrawAccount();
  // 绑定弹窗
  const [bind, setBind] = useToggle(false);
  // 绑定信息
  const [formData, setFormData] = useState({
    id: '',
    real_name: '',
    account_number: '',
    mobile: ''
  });
  // 微信账号
  const [bindWx, setBindWx] = useState(false);
  // 提示信息
  const [hint, setHint] = useState(false);
  // 图片操作
  const imageAction = useRecoilValue(ImageAction);
  // 状态
  const [isShow, toggle] = useToggle(false);
  // 头像
  const [avatar, setAvatar] = useState('');
  // 是否跳过
  const [isPass, setPass] = useRecoilState(IsPass);

  // 初始化
  const handleInit = async () => {
    // 获取数据
    const { account_list } = await getAccount();
    // 赋值
    account_list.length > 0 ? (() => {
      const result = account_list.filter((item: any) => item.account_type === 2);
      result.length > 0 ? setFormData(result[0]) : '';
      _.find(account_list, { account_type: 1 }) ? setBindWx(true) : '';
    })() : '';
  };

  // 处理提交
  const handleSubmit = useMutation(async () => {
    if (!nickName) {
      Toast.show({ content: '请输入昵称' });
      return false;
    }
    await editTeacher({ nickname: nickName });
    refresh();
    setVisible(false);
  });

  // 处理更新
  const handleUpdate = (field: string, value: string) => {
    setFormData((formData: any) => ({ ...formData, [field]: value }));
  };

  // 处理校验
  const handleCheck = () => {
    // 初始化
    let content = '', flag = false;
    // 解构
    const { real_name, account_number, mobile } = formData;
    // 校验
    validator.isEmpty(real_name) ? content = '请输入真实姓名'
      : validator.isEmpty(account_number) ? content = '请输入支付宝账号'
        : !validator.isMobilePhone(mobile, "zh-CN") ? content = '请输入正确的手机号码' : flag = true;
    !flag ? Toast.show(content) : '';
    return flag;
  };

  // 处理绑定
  const handleBind = useMutation(async () => {
    if (handleCheck()) {
      // 解构
      const { id, real_name, account_number, mobile } = formData;
      // 提交
      await saveWithdrawAccount({ id, type: '2', real_name, account_number, mobile });
      setBind(false);
    }
  });

  // 处理授权绑定
  const handleAuthBind = useMutation(async () => {
    !isApp() ? Toast.show({ content: '请下载知嘛App绑定' }) : bindWx ? setHint(true) : (async () => {
      // 检测客户端是否安装
      const status = await isWxInstalled();
      status ? (async () => {
        // 授权登录
        const res: any = await wxLogin();
        res.code ? (async () => {
          // 绑定
          await bindSnsByCode({ channel: 'weixin_app', code: res.code });
          Toast.show({ content: '绑定成功', icon: 'success' });
          setBindWx(true);
        })() : Toast.show({ content: '绑定失败', icon: 'fail' });
      })() : Toast.show({ content: '当前设备未安装微信客户端' });
    })();
  });

  // 处理解绑
  const handleUnBind = useMutation(async () => {
    // 处理账号解绑
    await unbindSns('weixin_app');
    setHint(false);
    Toast.show({ content: '解绑成功', icon: 'fail' });
    setBindWx(false);
  });

  // 处理头像
  const handleHeadPortrait = async (type: string | number) => {
    try {
      // 获取数据
      const { data, base64Data }: any = await getPicture(type);
      const url = await uploadOssByLocalPath(data);
      await editTeacher({ headimgurl: url });
      setAvatar(base64Data);
      refresh();
      // 关闭
      toggle(false);
    } catch (err: any) {
      Toast.show({ content: err.message === 'user canceled' ? '取消操作' : err.message, icon: 'fail', duration: 3000 });
    }
  };

  // 监听
  useEffect(() => {
    loginInfo ? (() => {
      setNickName(loginInfo?.nickname);
      handleInit();
    })() : '';
  }, [loginInfo]);

  // 监听
  useEffect(() => {
    // 文件上传
    const uploadFile = async (e: any) => {
      const file = e.target.files[0];
      const res = await uploadOss(file);
      await editTeacher({ headimgurl: res });
      refresh();
    };
    const fileDOM = document.getElementById('uploadFile');
    if (fileDOM) {
      uploadFileRef.current = fileDOM;
      fileDOM.addEventListener('change', uploadFile);
    }
    return () => {
      fileDOM && fileDOM.removeEventListener('change', uploadFile);
    };
  }, [uploadFileRef.current]);

  return (
    <div className="">
      <div className="px-4 py-3 flex justify-between items-center">
        <div className="text-[15px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.85)]">头像</div>
        <div className="ml-3 flex-1 flex justify-end items-center" onClick={() => isApp() ? toggle(true) : uploadFileRef.current?.click()}>
          <Avatar src={avatar || loginInfo?.avatar || DefaultAvatar} alt="" style={{'--size': '2rem', '--border-radius': '1rem', 'border': '1px solid #232323'}}/>
          <img src={RightIcon} alt="" className="ml-1.5 w-4 h-4"/>
        </div>
      </div>
      <div className="half-1px-line"/>
      <div className="px-4 py-3 flex justify-between items-center">
        <div className="text-[15px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.85)]">昵称</div>
        <div className="ml-3 flex-1 flex items-center" onClick={setVisible}>
          <div className="flex-1 text-[14px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.45)] text-right truncate-1">{loginInfo?.nickname}</div>
          <img src={RightIcon} alt="" className="ml-1.5 w-4 h-4"/>
        </div>
      </div>
      <div className="half-1px-line"/>
      <div className="px-4 py-3 flex justify-between items-center" onClick={() => loginInfo?.beat_subjects.length === 0 && isPass ? setPass(false) : Toast.show({content: '无法编辑，请联系客服'})}>
        <div className="text-[15px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.85)]">擅长科目</div>
        <div className="ml-3 flex-1 flex subject-box overflow-scroll">
          {
            loginInfo?.beat_subjects.map((item, index: number) => (
              <div key={index} className="ml-3 subject-btn">
                {PHASE_INFO[item.period]}{item.subject}
              </div>
            ))
          }
          <div className=""></div>
        </div>
      </div>
      <div className="half-1px-line"/>
      <div className="px-4 py-3 flex justify-between items-center">
        <div className="text-[15px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.85)]">支付宝</div>
        <div className="ml-3 flex-1 flex items-center" onClick={setBind}>
          <div className="flex-1 text-[14px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.45)] text-right truncate-1">{formData.account_number ? formData.account_number : '未绑定'}</div>
          <img src={RightIcon} alt="" className="ml-1.5 w-4 h-4"/>
        </div>
      </div>
      <div className="half-1px-line"/>
      {/*<div className="px-4 py-3 flex justify-between items-center">*/}
      {/*  <div className="text-[15px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.85)]">微信</div>*/}
      {/*  <div className="ml-3 flex-1 flex items-center" onClick={() => handleAuthBind.isLoading ? '' : handleAuthBind.mutate()}>*/}
      {/*    <div className="flex-1 text-[14px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.45)] text-right truncate-1">{bindWx ? '已绑定' : '未绑定'}</div>*/}
      {/*    <img src={RightIcon} alt="" className="ml-1.5 w-4 h-4"/>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className="half-1px-line"/>*/}
      <Popup visible={visible} onMaskClick={() => setVisible(false)} className="w-full" bodyStyle={{borderTopLeftRadius: '1.25rem', borderTopRightRadius: '1.25rem'}}>
        <div className="safe-padding-bottom">
          <div className="p-4">
            <div className="text-[16px] family-PingFangSC-PingFang font-semibold text-[#232323]">编辑昵称</div>
            <Input value={nickName} placeholder="请输入昵称" onChange={(val) => setNickName(val)} className="mt-5 custom-input"/>
            <div className={`mt-10 submit-btn ${handleSubmit.isLoading ? 'opacity-70' : ''}`} onClick={() => handleSubmit.isLoading ? '' : handleSubmit.mutate()}>确定</div>
          </div>
        </div>
      </Popup>
      <Popup visible={bind} onMaskClick={() => setBind(false)} className="w-full" bodyStyle={{borderTopLeftRadius: '1.25rem', borderTopRightRadius: '1.25rem'}}>
        <div className="safe-padding-bottom">
          <div className="p-4">
            <div className="text-[16px] family-PingFangSC-PingFang font-semibold text-[#232323]">编辑支付宝账号</div>
            <div className="">
              <Input value={formData.real_name} placeholder="请输入真实姓名" onChange={(val) => handleUpdate('real_name', val)} className="mt-5 custom-input"/>
              <Input value={formData.account_number} placeholder="请输入支付宝账号" onChange={(val) => handleUpdate('account_number', val)} className="mt-3 custom-input"/>
              <Input value={formData.mobile} placeholder="请输入手机号码" onChange={(val) => handleUpdate('mobile', val)} className="mt-3 custom-input"/>
            </div>
            <div className={`mt-10 submit-btn ${handleBind.isLoading ? 'opacity-70' : ''}`} onClick={() => handleBind.isLoading ? '' : handleBind.mutate()}>确定</div>
          </div>
        </div>
      </Popup>
      <CenterPopup visible={hint} onMaskClick={() => setHint(false)} className="w-full" style={{'--border-radius': '1rem', '--min-width': '80vw'}}>
        <div className="p-4">
          <div className="text-[18px] family-PingFangSC-PingFang font-semibold text-[#232323] text-center">提示</div>
          <div className="mt-4 text-[15px] family-PingFangSC-PingFang text-[rgba(36,36,36,0.65)] text-center">确定解绑微信账号吗？</div>
          <div className={`mt-10 submit-btn ${handleUnBind.isLoading ? 'opacity-70' : ''}`} onClick={() => handleUnBind.isLoading ? '' : handleUnBind.mutate()}>确定</div>
        </div>
      </CenterPopup>
      <input type="file" id="uploadFile" accept="image/*" hidden/>
      <ActionSheet visible={isShow} actions={imageAction} onClose={() => toggle(false)} cancelText="取消" onAction={action => handleHeadPortrait(action.key)} />
    </div>
  )
}