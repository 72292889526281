import { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useToggle } from "react-use";
import { useMutation } from "react-query";
import { useRecoilState } from "recoil";
import { Popup, CenterPopup, Toast } from "antd-mobile";
import Layout from "@/components/Layout";
import { useLoginInfo } from "@/hooks/user";
import { PHASE_INFO } from "@/constants/user";
import _ from "lodash";
import { teacherTaskList } from "@/services";
import TaskItem from "@/components/TaskItem";
import Nothing from "@/components/Nothing";
import { ScreenData } from "@/models/task";
import ReactPlayer from "react-player";
import { isApp } from "@/utils";
import { IsPass, IsSubscribe } from "@/models/user";

import Right from "@/assets/icon/right.png";
import Instruction from "@/assets/icon/instruction.png";
import VideoClose from "@/assets/icon/video-close.png";
import Subscribe from "@/assets/icon/subscribe.png";
import Perfection from "@/assets/icon/perfection.png";
import ExplainIcon from "@/assets/icon/explain.png";

let timer: any = {};

export default function Home() {
  // 用户登录信息
  const { loginInfo } = useLoginInfo();
  // 筛选弹窗状态
  const [visible, setVisible] = useToggle(false);
  // 筛选条件
  const [screenData, setScreenData] = useRecoilState(ScreenData);
  // 数据列表
  const [dataList, setDataList] = useState<TaskInfo[]>([]);
  // 视频播放状态
  const [play, setPlay] = useToggle(false);
  // 是否订阅
  const [isSubscribe, setIsSubscribe] = useRecoilState(IsSubscribe);
  // 是否跳过
  const [isPass, setPass] = useRecoilState(IsPass);
  // 路由
  const navigate = useNavigate();

  // 筛选科目
  const screenSubject = useMemo(() => screenData.length > 0 ? screenData.map((item: any) => `${PHASE_INFO[item.period]}${item.subject}`).join('、'): '我擅长的科目', [screenData]);

  // 开始筛选
  const startScreen = () => {
    clearInterval(timer);
    setVisible(true);
  };

  // 处理选择
  const handleSelected = (data: any) => {
    const flag = _.find(screenData, { period: data.period, subject: data.subject });
    const result = flag ? screenData.filter((item: any) => item.period !== data.period || item.period === data.period && item.subject !== data.subject) : [...screenData, data];
    setScreenData(result);
  };

  // 处理筛选
  const handleScreen = async () => {
    // 数据格式化
    const period_subject = screenData.length > 0 ? screenData.map((item: any) => `${item.period}-${item.subject}`).join(',') : '';
    // 获取列表数据
    const { list } = await teacherTaskList(period_subject);
    setDataList(list);
  };

  // 处理更新
  const handleRefresh = useMutation(async () => {
    clearInterval(timer);
    setVisible(false);
    await handleScreen();
    timer = setInterval(() => handleScreen(), 5000);
  });

  // 处理订阅
  const handleSubscribe = () => {
    Toast.show({ content: '您将收到新任务消息推送', icon: 'success' });
    const str: any = localStorage.getItem('user_subscribe') ? `${localStorage.getItem('user_subscribe')}, ${loginInfo?.id}` : loginInfo?.id;
    localStorage.setItem('user_subscribe', str);
    setIsSubscribe(true);
  };

  // 初始化
  useEffect(() => {
    loginInfo && loginInfo?.beat_subjects.length > 0 && loginInfo?.status === 1 && loginInfo?.credit > 0 && handleRefresh.mutate();
    return () => clearInterval(timer);
  }, [loginInfo]);

  return (
    <Layout paddingTop={false}>
      <div className="page-box bg-[#F7F7F7]">
        <div className={`fixed left-0 w-full box-border z-10 bg-[#F7F7F7] ${isApp() ? 'page-safe-app' : 'page-safe-other'}`}>
          <div className="px-4 flex justify-between items-center">
            <div className="text-[24px] family-PingFangSC-PingFang font-semibold text-[#232323]">任务大厅</div>
            {/*<img src={Instruction} alt="" className="w-[104px] h-[30px]" onClick={() => setPlay(true)}/>*/}
            <img src={ExplainIcon} alt="" className="w-[104px] h-[30px]" onClick={() => navigate(`/agreement/3485`)}/>
          </div>
          <div className="p-4 pb-2 flex items-center">
            <div className="text-[16px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.45)]">筛选：</div>
            <div className="flex-1 flex items-center" onClick={() => loginInfo?.beat_subjects.length === 0 && isPass ? setPass(false) : startScreen()}>
              <div className="text-[14px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.85)] truncate-1">{screenSubject}</div>
              <img src={Right} alt="" className="ml-1 w-4 h-4"/>
            </div>
          </div>
        </div>
        <div className={`p-4 ${isApp() ? 'content-safe-app' : 'content-safe-other'}`}>
          <Nothing state={dataList.length > 0} marginTop="mt-20">
            <div className="grid grid-cols-1 gap-3">
              {
                dataList.map(item => (
                  <div key={item.id} className="">
                    <TaskItem data={item} handleRefresh={() => handleRefresh.mutate()}/>
                  </div>
                ))
              }
            </div>
          </Nothing>
        </div>
        {
          loginInfo?.beat_subjects.length === 0 && isPass ? <div className="absolute bottom-24 text-center w-full">
            <img src={Perfection} alt="" className="w-[82px] h-[30px] animate-bounce" onClick={() => setPass(false)}/>
          </div> : !isSubscribe ? <div className="absolute bottom-24 text-center w-full">
            <img src={Subscribe} alt="" className="w-[104px] h-[30px] animate-bounce" onClick={handleSubscribe}/>
          </div> : ''
        }
        <Popup visible={visible} onMaskClick={() => handleRefresh.isLoading ? '' : handleRefresh.mutate()} className="w-full" bodyStyle={{borderTopLeftRadius: '1.25rem', borderTopRightRadius: '1.25rem'}}>
          <div className="safe-padding-bottom">
            <div className="p-4">
              <div className="text-[16px] family-PingFangSC-PingFang font-semibold text-[#232323]">筛选科目</div>
              <div className="mt-4 grid grid-cols-3 gap-x-4 gap-y-3">
                {
                  loginInfo?.beat_subjects.map((item, index: number) => (
                    <div key={index} onClick={() => handleSelected(item)}
                         className={`${_.find(screenData, {period: item.period, subject: item.subject}) ? 'primary-btn' : 'default-btn'}`}>{PHASE_INFO[item.period]}{item.subject}</div>
                  ))
                }
              </div>
              <div className={`mt-10 submit-btn ${handleRefresh.isLoading ? 'opacity-70' : ''}`} onClick={() => handleRefresh.isLoading ? '' : handleRefresh.mutate()}>确认</div>
            </div>
          </div>
        </Popup>
        <CenterPopup visible={play} style={{'--max-width': '100vw', '--min-width': '100vw', '--border-radius': 'none' }}>
          <img src={VideoClose} alt="" className="w-7 h-7 custom-video-close" onClick={() => setPlay(false)} />
          <div className="flex justify-center items-center">
            <ReactPlayer url={play ? 'https://zhimaapp.timasky.com/material/instructions.mp4' : ''} playing={play} controls={true} playsinline={true} onEnded={() => setPlay(false)} className="custom-video" height={window.innerHeight} />
          </div>
        </CenterPopup>
      </div>
    </Layout>
  )
}