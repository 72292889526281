import { hidePermissionModal, showPermissionModal } from "@/utils";
import { Dialog } from "antd-mobile";

interface PermissionGranted {
  name: string                //权限名，字符串类型。
  granted: boolean             //是否允许，如果从未请求过该权限或者用户未做出过选择时将返回false，布尔类型。
  undetermined: boolean        //是否从未请求过该权限或者用户未做出过选择，只支持iOS，注意：请求notification权限时无法获取该状态，布尔类型。
  limited: boolean             //该字段仅photos权限有效，表示访问相册是否有限制，当受限时，应用只能获取到用户在相册选定的那部分资源，只支持iOS 14及以上系统，布尔类型。
  reducedAccuracy: boolean     //该字段仅location、locationAlways有效，返回当前是否为模糊定位，只支持iOS 14及以上系统，布尔类型。在iOS 14以上系统中，用户可以选择只对应用授权模糊定位，如果应用对定位精度要求高，则可以在判断为模糊定位后请求locationFullAccuracy权限，系统将弹出开启精准定位弹框，用户可以再次进行选择。
}

/**
 * 获取 APP 本地值（本地永久存储）
 * @param key
 * @returns {*}
 */
export function getLocalDate(key: string): string {
  return window.api.getPrefs({ sync: true, key })
}

/**
 * 设置 APP 本地值（本地永久存储）
 * @param key
 * @param value
 */
export function setLocalDate(key: string, value: string) {
  window.api.setPrefs({ key, value })
}

// 是否有权限（单个）
export const hasOnePermission = (permission: string): PermissionGranted => {
  const res = window.api.hasPermission({
    list: [permission]
  })
  return res[0]
}

// 请求权限（单个）
export const requestOnePermission = (permissionKey: PermissionKey, desc?: string) => {
  return new Promise((resolve, reject) => {
    console.log("请求权限：", permissionKey)
    if(hasOnePermission(permissionKey).granted) {
      resolve({ granted: true })
      return
    }

    const permissions: Record<PermissionKey, any> = {
      'camera': { title: '相机权限', desc: '用于拍照和录制视频' },
      'photos': { title: '存储权限', desc: '用于上传图片' },
      'storage': { title: '存储权限', desc: '用于存储图片和文件' },
      'notification': { title: '通知权限', desc: '用于接受客服消息、订阅消息等通知' },
      'contacts': { title: '联系人权限', desc: '用于发现您通讯录中的好友' },
      'microphone': { title: '录制权限', desc: '用于录制音频' },
      'location': { title: '定位权限', desc: '用于获取您定位' },
      'phone': { title: '电话权限', desc: '用于拨打电话' },
      'sms': { title: '短信权限', desc: '用于发送短信等' },
    }

    const permission = permissions[permissionKey]

    // 如果否拒绝过，展示提示
    if (getLocalDate(`permission_${permissionKey}`) === 'denied') {
      Dialog.alert({
        title: "权限提示",
        content: `您拒绝了「${permissions[permissionKey].title}」权限，无法${desc || permissions[permissionKey].desc}，但不影响您正常使用 APP。`,
        confirmText: "我知道了",
      })
      return
    }

    Dialog.confirm({
      title: "权限申请",
      content:  `APP 需要获取「${permission.title}」权限，用于${desc || permission.desc}，禁用该权限不影响其他功能，是否允许？`,
      cancelText: "不允许",
      confirmText: "允许",
      onConfirm: () => {
        showPermissionModal(permission.title + "使用说明", `用于${desc || permission.desc}`)
        window.api.requestPermission({
          list: [permissionKey]
        }, (res: any) => {
          hidePermissionModal()
          if (res?.list[0]?.granted) {
            resolve(res?.list[0])
          } else {
            setLocalDate(`permission_${permissionKey}`, 'denied')
            reject({
              res
            })
          }
        })
      },
      onCancel: () => {
        setLocalDate(`permission_${permissionKey}`, 'denied')
        reject()
      }
    })
  })
}

// 安全区
export const safeArea = () => window.api.safeArea;

/**
 * https://docs.apicloud.com/Client-API/api#1
 * 获取图片以及视频媒体文件
 * @param sourceType 源类型
 * @param destinationType 返回数据类型
 */
export const getPicture = async (sourceType: string | number, destinationType = 'base64') => {
  // 校验权限
  if (sourceType === 'camera' && !hasOnePermission('camera').granted) {
    try {
      await requestOnePermission('camera')
    } catch (error) {
      throw new Error('请开启相机权限')
    }
  }

  if (sourceType !== 'camera' && !hasOnePermission('photos').granted) {
    try {
      await requestOnePermission('photos')
    } catch (error) {
      throw new Error('请开启存储权限')
    }
  }

  return new Promise((resolve, reject) => {
    window.api.getPicture({ sourceType, destinationType }, function (ret: any, err: { msg: string }) {
      if (ret) {
        if (ret.data) {
          resolve(ret);
        } else {
          reject(new Error('未选择图片'))
        }
      } else {
        reject(new Error(err.msg));
      }
    });
  });
};