/**
 * FNScanner 模块
 * https://docs.apicloud.com/Client-API/Func-Ext/FNScanner
 * 使用 1.2.0 版本处理兼容
 */
import { hasOnePermission, requestOnePermission } from "@/apicloud/api";

// 打开扫描控件
export const openFNScan = () => {
  return new Promise((resolve, reject) => {
    window.modules.FNScanner.open({ autorotation: true, isPush: true }, async (ret: any, err: any) => {
      // 相机权限校验
      if (ret.eventType === 'cameraError' && !hasOnePermission('camera').granted) {
        try {
          await requestOnePermission('camera');
        } catch (error) {
          reject({ message: '请开启相机权限' });
        }
      }
      // 相册权限校验
      if ((ret.eventType === 'albumError' || ret.eventType === 'selectImage') && !hasOnePermission('photos').granted) {
        try {
          await requestOnePermission('photos');
        } catch (error) {
          reject({ message: '请开启存储权限' });
        }
      }
      ret.eventType === 'success' ? resolve(ret) : ret.eventType === 'fail' ? reject({ message: ret.content }) : '';
    });
  });
};
