import React, { ReactElement, useCallback, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";
import LoginModal from "./LoginModal";
import { useQueryLoginInfo } from "@/hooks/user";
import { useDicts } from "@/hooks/dict";
import { getDeviceId } from "@/utils";
import { JPUSH_ID_KEY } from "@/constants/common";
import { bindPushID } from "@/services";

type Props = {
  name?: string;
  children: ReactElement;
  custom?: boolean;
  dict?: boolean;
};

// react-query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      cacheTime: 300000, // 默认缓存 5 分钟（并不代表 5 分钟后才获取数据，是否获取数据由 staleTime 决定）
    },
  },
});

// 设置 rem 基准
const baseSize = 16;
const setRem = () => {
  const scale = document.documentElement.clientWidth / 375;
  document.documentElement.style.fontSize =
    baseSize * Math.min(scale, 2) + "px";
};
setRem();
window.addEventListener("resize", () => setRem(), false);

export default function AppContainer({
  children,
  custom = false,
  dict = false,
}: Props) {
  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <RecoilNexus />
        <Global custom={custom} dict={dict}>
          {children}
        </Global>
      </RecoilRoot>
    </QueryClientProvider>
  );
}

function Global({
  children,
  custom,
  dict,
}: {
  children: ReactElement;
  custom?: boolean;
  dict?: boolean;
}) {
  dict && useDicts();
  !custom && useQueryLoginInfo();

  const loginSuccess = useCallback(() => {
    // 给当前用户绑定推送 ID
    const jpushId = localStorage.getItem(JPUSH_ID_KEY);
    if (jpushId) {
      bindPushID(jpushId);
    }
  }, []);

  return (
    <>
      <LoginModal onSuccess={loginSuccess} />
      {children}
    </>
  );
}
