import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { TabBar } from "antd-mobile";
import _ from "lodash";
import { OrderCount } from "@/models/order";
import { teacherHandlerTasks } from "@/services";
import { useMutation } from "react-query";
import { useLoginInfo } from "@/hooks/user";

import TabHomeDefault from "@/assets/icon/tab-home-default.png";
import TabHomeActive from "@/assets/icon/tab-home-active.png";
import TabOrderDefault from "@/assets/icon/tab-order-default.png";
import TabOrderActive from "@/assets/icon/tab-order-active.png";
import TabMeDefault from "@/assets/icon/tab-me-default.png";
import TabMeActive from "@/assets/icon/tab-me-active.png";
import TabQuestionDefault from "@/assets/icon/tab-question-default.png";
import TabQuestionActive from "@/assets/icon/tab-question-active.png";

export default function BottomTab() {
  // 用户登录信息
  const { loginInfo } = useLoginInfo();
  // 路径
  const { pathname } = useLocation();
  // 路由
  const navigate = useNavigate();
  // 订单数
  const [orderCount, setOrderCount] = useRecoilState(OrderCount);

  /**
   * 切换路由
   * @param path 路径
   */
  const changeRoute = (path: string) => {
    if (pathname !== path) {
      navigate(path, { replace: true });
    }
  };

  const renderIcon = (active: boolean, activeImage: any, defaultImage: any) => {
    return active ? <img className="w-6" src={activeImage} alt="" /> : <img className="w-6" src={defaultImage} alt=""/>
  }

  // 处理统计
  const handleCount = useMutation(async () => {
    // 获取数据
    const { list } = await teacherHandlerTasks();
    setOrderCount((orderCount) => ({ ...orderCount, order: list.length > 0 ? list.length.toString() : '' }));
  });

  // 监听
  useEffect(() => {
    pathname !== '/order' && loginInfo && loginInfo?.id && loginInfo?.status === 1 && loginInfo?.credit > 0 && handleCount.mutate();
  }, [pathname, loginInfo]);

  return (
    <div className="">
      {
        _.includes(['/', '/order', '/question', '/me'], pathname) && (
          <div className={`bottom-tab fixed bottom-0 left-0 w-full ${_.includes(['/', '/order', '/question'], pathname) ? 'bg-[#F7F7F7]' : 'bg-white'} overflow-hidden safe-padding-bottom`}
               style={{borderTop: '0.03125rem solid rgba(0,0,0,0.08)'}}>
            <div>
              <TabBar onChange={changeRoute} activeKey={pathname} className="py-1">
                <TabBar.Item key='/' icon={a => renderIcon(a, TabHomeActive, TabHomeDefault)} title='大厅' badge={orderCount.total}/>
                <TabBar.Item key='/order' icon={a => renderIcon(a, TabOrderActive, TabOrderDefault)} title='已接单' badge={orderCount.order}/>
                <TabBar.Item key='/question' icon={a => renderIcon(a, TabQuestionActive, TabQuestionDefault)} title='题库'/>
                <TabBar.Item key='/me' icon={a => renderIcon(a, TabMeActive, TabMeDefault)} title='我的'/>
              </TabBar>
            </div>
          </div>
        )
      }
    </div>
  )
}
