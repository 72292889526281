import { ReactNode } from "react";
import { Skeleton } from "antd-mobile";

type Props = {
  isLoading: boolean, // 加载状态
  padding?: string, // 内边距
  title?: boolean, // 显示标题
  lineCount?: number, // 行数
  children: ReactNode, // 子组件
}

export default function CustomSkeleton({ isLoading, padding = '', title = false, lineCount = 3, children }: Props) {
  return (
    <div className="">
      {
        isLoading ? title ? (
          <div className={padding}>
            <Skeleton.Title animated />
            <Skeleton.Paragraph animated lineCount={lineCount} />
          </div>
        ) : (
          <div className={padding}>
            <Skeleton.Paragraph animated lineCount={lineCount} />
          </div>
        ) : (
          <div className="">
            {children}
          </div>
        )
      }
    </div>
  )
}
