/**
 * wxPlus 模块
 * https://docs.apicloud.com/Client-API/Open-SDK/wxPlus
 */

import { toUTF8Hex } from "@/utils/crypto";

// 判断当前设备是否安装微信客户端
export const isWxInstalled = () => {
  return new Promise((resolve) => {
    window.modules.wxPlus.isInstalled((ret: any) => {
      resolve(ret.installed);
    });
  });
};

// 登录授权
export const wxLogin = () => {
  return new Promise((resolve) => {
    window.modules.wxPlus.auth((ret: any) => {
      resolve(ret);
    });
  });
};

/**
 * 获取用户信息
 * @param params accessToken 接口调用凭证 openId 授权用户唯一标识
 */
export const getWxUserInfo = (params: {
  accessToken: string;
  openId: string;
}) => {
  return new Promise((resolve) => {
    window.modules.wxPlus.getUserInfo(params, (ret: any) => {
      resolve(ret);
    });
  });
};

/**
 * 分享链接
 * @param params scene 场景 contentUrl 链接 title 标题 description 描述 thumb 缩略图
 */
export const shareWxWebpage = (params: {
  scene: string;
  contentUrl: string;
  title: string;
  description?: string;
}) => {
  return new Promise((resolve) => {
    window.modules.wxPlus.shareWebpage(
      { thumb: "widget://icon/logo.png", ...params },
      (ret: any, err: any) => {
        resolve(ret);
      }
    );
  });
};

/**
 * 分享小程序
 */
export const shareProgram = (params: {
  apiKey?: string;
  scene?: string;
  thumb?: string;
  title: string;
  description: string;
  webpageUrl: string;
  userName: string;
  path: string;
}) => {
  return new Promise((resolve) => {
    window.modules.wxPlus.shareProgram(
      { thumb: "widget://icon/logo.png", scene: "session", ...params },
      (ret: any, err: any) => {
        resolve(ret);
      }
    );
  });
};

/**
 * 分享图片
 * @param params scene 场景 contentUrl 图片地址
 */
export const shareWxImage = (params: { scene: string, contentUrl: string }) => {
  return new Promise((resolve) => {
    window.modules.wxPlus.shareImage(params, (ret: any, err: any) => {
        resolve(ret);
      }
    );
  });
}

/**
 * 打开小程序
 * 支持小程序内链接和 https 链接
 */
export const launchMiniProgram = (path: string, hiddenBar = false) => {
  if (/^https?/.test(path)) {
    if (hiddenBar) {
      path = `/pages/webViewCustom/webViewCustom?data=${toUTF8Hex(path)}`
    } else {
      path = `/pages/webViewHex/webViewHex?data=${toUTF8Hex(path)}`
    }
  }

  return new Promise((resolve, reject) => {
    window.modules.wxPlus.launchMiniProgram({
      miniProgramType: 'release',
      userName: 'gh_87ce325e2847',
      path
    }, (ret: any, err: any) => {
        if(ret.status) {
          resolve(ret);
        } else {
          // -1（未知错误）
          // 0（成功）
          // 1（apiKey非法）
          // 7 (注册SDK失败)
          reject(err.code)
        }
      }
    );
  });
}
