import { atom } from "recoil";

// 图片操作
export const ImageAction = atom({
  key: 'ImageAction',
  default: [
    { key: 'library', text: '从图片库选择' },
    { key: 'camera', text: '拍照' },
    { key: 'album', text: '从手机相册选择' },
  ],
});

// 客服弹窗状态
export const ServicePopupState = atom<boolean>({
  key: 'ServicePopupState',
  default: false,
});