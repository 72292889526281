import { useEffect } from "react";
import { useToggle } from "react-use";
import { CenterPopup, Toast } from "antd-mobile";
import { useGoService } from "@/hooks/common";
import { useLoginInfo } from "@/hooks/user";

export default function HintPopup() {
  // 用户登录信息
  const { loginInfo } = useLoginInfo();
  // 弹窗状态
  const [visible, setVisible] = useToggle(false);
  // 打开客服
  const go = useGoService({ type: 'custom', from_name: '知嘛App' });

  // 监听
  useEffect(() => {
    loginInfo && loginInfo?.level !== 0 && (loginInfo?.status === 2 || loginInfo?.credit <= 0) ? setVisible(true) : setVisible(false);
  }, [loginInfo]);

  return (
    <div className="">
      <CenterPopup visible={visible} onMaskClick={() => (loginInfo?.status === 1 && loginInfo?.credit > 0) ? setVisible(false) : Toast.show({ content: '请联系客服处理' }) } className="w-full" style={{'--border-radius': '1rem', '--min-width': '80vw'}}>
        <div className="p-4">
          <div className="text-[18px] family-PingFangSC-PingFang font-semibold text-[#232323] text-center">{loginInfo?.status === 2 ? '审核中' : '温馨提示'}</div>
          <div className="mt-4 text-[15px] family-PingFangSC-PingFang text-[rgba(36,36,36,0.65)] text-center leading-6">
            {loginInfo?.status === 2 ? '资格信息正在审核中，预计 1～3 个工作日，如有问题，可联系客服。' : `您存在违规操作，信誉分已降低为 ${loginInfo?.credit} 分且无法接单，如有问题，可联系客服。`}
          </div>
          <div className={`mt-10 submit-btn`} onClick={go}>联系客服</div>
        </div>
      </CenterPopup>
    </div>
  )
}