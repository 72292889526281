import * as Sentry from "@sentry/react";
import React from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

export function initSentry() {
  Sentry.init({
    dsn: "https://e639f8fb6791ed1b45fc2688e13df634@sentry.daanjiexi.com:442/4",
    enabled: import.meta.env.MODE === "production",
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracePropagationTargets: ["zhimaapp.timasky.com"],
    release: /\/(.*)\.html/g.exec(location.pathname)?.[1] || "none", // 取 html 的文件名作为版本
  });
}
