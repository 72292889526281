import { answerAxios } from "@/utils/axios";

// 注册推送ID
export const bindPushID = (pushId: string) => answerAxios.post('api/may_auth/user/bind_push_info', {}, {
  params: {
    deviceid: pushId,
    app: 'zhima'
  },
  errMsg: false,
});

// 扫码登录
export const loginByQrCode = (code: string) => answerAxios.post(`/api_go/may_auth/sideline/login_by_qr_code`, { code }, { successMsg: '授权成功' });

// 权限审核
export const addAuthReview = (data: any) => answerAxios.post(`/api_go/may_auth/sideline/add_auth_review`, data, { successMsg: '提交成功' });

// 获取审核结果
export const getAuthReview = (auth_type: string) => answerAxios.get(`/api_go/may_auth/sideline/get_auth_review`, { params: { auth_type }});