import { useRecoilState } from "recoil";
import { Popup } from "antd-mobile";
import { ServicePopupState } from "@/models/dict";

import QrCode from "@/assets/qrcode.jpeg";

export default function ServicePopup() {
  // 弹窗状态
  const [visible, setVisible] = useRecoilState(ServicePopupState);

  return (
    <Popup visible={visible} onMaskClick={() => setVisible(false)} className="w-full" bodyStyle={{borderTopLeftRadius: '1.25rem', borderTopRightRadius: '1.25rem'}}>
      <div className="safe-padding-bottom">
        <div className="p-4">
          <div className="text-[16px] family-PingFangSC-PingFang font-semibold text-[#232323]">客服二维码</div>
          <div className="mt-4 flex justify-center items-cneter">
            <img src={QrCode} alt="" className="w-[150px] h-[150px]"/>
          </div>
          <div className="mt-3 text-[14px] family-PingFangSC-PingFang text-[rgba(36,36,36,0.65)] leading-6">
            <div className="font-medium text-[#333]">提示：</div>
            <div className="mt-1">请扫描二维码添加客服企业微信。</div>
          </div>
          <div className={`mt-10 submit-btn`} onClick={() => setVisible(false)}>我知道了</div>
        </div>
      </div>
    </Popup>
  )
}