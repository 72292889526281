import { useNavigate } from "react-router-dom";
import Layout from "@/components/Layout";
import SafeNav from "@/components/SafeNav";

import RightIcon from "@/assets/icon/right.png";

export default function PrivacySetting() {
  // 路由
  const navigate = useNavigate();

  return (
    <Layout paddingBottom={false}>
      <SafeNav title="隐私设置" isService={false}/>
      <div className="h-full bg-[#F7F7F7]">
        <div className="pt-[42px] bg-white">
          <div className="px-4 py-3 flex justify-between items-center" onClick={() => navigate(`/personalInventory`)}>
            <div className="text-[15px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.85)]">个人信息收集清单</div>
            <img src={RightIcon} alt="" className="ml-1.5 w-4 h-4"/>
          </div>
          <div className="half-1px-line"/>
          <div className="px-4 py-3 flex justify-between items-center" onClick={() => navigate(`/otherInventory`)}>
            <div className="text-[15px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.85)]">第三方信息共享清单</div>
            <img src={RightIcon} alt="" className="ml-1.5 w-4 h-4"/>
          </div>
        </div>
      </div>
    </Layout>
  )
}