import { useGoService } from "@/hooks/common";

import ServiceIcon from "@/assets/icon/service.png";

type Props = {
  from?: {
    type: string; // 类型
    from_name?: string; // 来源
  };
  className?: string, // 样式
  fontSize?: string, // 字体大小
};

export default function MessageIcon({ from = { type: 'custom', from_name: '知嘛App' }, className = 'w-6 h-6', fontSize = 'text-[12px]' }: Props) {
  // 打开客服
  const go = useGoService(from);

  return (
    <div className="flex items-center" onClick={go}>
      <img className={className} src={ServiceIcon} alt=""/>
      <div className={`ml-1.5 ${fontSize} family-PingFangSC-PingFang text-[rgba(36,36,36,0.65)]`}>客服</div>
    </div>
  )
}