import { useState, useEffect, useMemo } from "react";
import { useMutation } from "react-query";
import { useToggle } from "react-use";
import { useRecoilState } from "recoil";
import { Popup, ImageViewer, CenterPopup, Avatar, TextArea, Toast, Rate, InfiniteScroll } from "antd-mobile";
import { TASK_ITEM_COLOR, TASK_AWARD_STATUS, TASK_AWARD_STATUS_TEXT_COLOR } from "@/constants/task";
import { GRADE_DATA } from "@/constants/user";
import dayjs from "dayjs";
import { getTaskInfo, orderTask, teacherCancelTaskPendingTask, teacherAnswerComment } from "@/services";
import { TaskDetail } from "@/models/task";
import { openWin } from "@/utils";
import 'dayjs/locale/zh-cn';
import relativeTime from 'dayjs/plugin/relativeTime';
import _ from "lodash";
import { useLoginInfo } from "@/hooks/user";

dayjs.extend(relativeTime);
dayjs.locale('zh-cn');

import DefaultAvatar from "@/assets/default-avatar.png";
import HintIcon from "@/assets/icon/hint.png";
import IncomeDefault from "@/assets/icon/income-default.png";
import IncomeActive from "@/assets/icon/income-active.png";
import UserIcon from "@/assets/icon/user.png";
import OtherIcon from "@/assets/icon/other.png";
import RightIcon from "@/assets/icon/right.png";
import CancelIcon from "@/assets/icon/cancel.png";

type Props = {
  data: TaskInfo, // 任务信息
  handleRefresh?: any, // 处理刷新
};

// H5 教师答疑
const h5TeacherChat = import.meta.env.VITE_H5_TEACHER_CHAT_URL;

// 旋转角度
let imgAngle: number[] = [];
// 定时器
let timer: any;

export default function TaskItem({ data, handleRefresh }: Props) {
  // 用户登录信息
  const { loginInfo } = useLoginInfo();
  // 弹窗状态
  const [visible, setVisible] = useToggle(false);
  // 问题信息
  const [taskDetail, setTaskDetail] = useRecoilState(TaskDetail);
  // 提示弹窗
  const [isShow, toggle] = useToggle(false);
  // 提示信息
  const [msg, setMsg] = useState('');
  // 反馈弹窗
  const [feedBack, setFeedBack] = useToggle(false);
  // 反馈选项配置
  const items = ['年级错误', '科目错误', '题型错误', '未说做哪题', '图片看不清', '其他'];
  // 反馈类型
  const [cancelReason, setCancelReason] = useState('');
  // 其他描述
  const [content, setContent] = useState('');
  // 倒计时
  const [time, setTime] = useState(0);
  // 默认值
  const second = 5;
  // 黑名单
  const blacklist = ['15665765081'];
  // 评价弹窗
  const [evaluate, setEvaluate] = useToggle(false);
  // 数据列表
  const [dataList, setDataList]: any = useState([]);
  // 分页
  const [cursor, setCursor] = useState<string | null>('');
  // 综合评分
  const [otherInfo ,setOtherInfo]: any = useState();

  // 显示时间
  const showTime = useMemo(() => data.status === 2 && data.created_at ? `（${dayjs(data.created_at).fromNow()}）` : data.status === 3 && data.order_task_time ? `（${dayjs(data.order_task_time).fromNow()}）` : data.finish_time ? `（${dayjs(data.finish_time).format('MM-DD HH:mm:ss')}）` : '', [data.status, data.created_at, data.order_task_time, data.finish_time]);

  // 获取任务详情
  const handleInfo = useMutation(async () => {
    // 获取数据
    const res = await getTaskInfo(data.id);
    // 处理校验
    res.code === 0 ? res.data.status !== 4 && res.data.chat_id ? handleJump(res.data.chat_id) :  (() => {
      // 赋值
      setTaskDetail(res.data);
      setVisible(true);
    })() : (() => {
      setMsg(res.msg);
      toggle(true);
      handleRefresh && handleRefresh();
    })();
  });

  // 处理接单
  const handleOrder = useMutation(async () => {
    // 校验
    const res: any = await orderTask({ task_id: data?.id });
    // 处理校验
    res.code === 0 ? handleJump(res.data.chat_id) : (() => {
      setVisible(false);
      setMsg(res.msg);
      toggle(true);
      handleRefresh && handleRefresh();
    })();
  });

  /**
   * 处理跳转
   * @param chat_id 聊天Id
   */
  const handleJump = (chat_id: string) => {
    setVisible(false);
    const url = `${h5TeacherChat}?type=3&tqa_id=${data?.id}&chatid=${chat_id}&t=${new Date().getTime()}&hiddenbar=true&top=${window.api?.safeArea.top || ''}&bottom=${window.api?.safeArea.bottom || ''}&token=${localStorage.getItem('token') || ''}`;
    setTimeout(() => openWin(url, 'teacher'), 10);
    handleRefresh && handleRefresh();
  };

  // 处理反馈
  const handleFeedback = useMutation(async () => {
    // 校验
    if (cancelReason === '其他' && !content) {
      Toast.show({ content: '请输入错误原因' });
      return false;
    }
    const cancel_reason = cancelReason === '其他' ? content : cancelReason;
    const res: any = await teacherCancelTaskPendingTask({ task_id: taskDetail?.id, cancel_reason });
    setFeedBack(false);
    setVisible(false);
    res.code === 0 ? Toast.show({ content: '提交成功', icon: 'success' }) : (() => {
      setMsg(res.msg);
      toggle(true);
    })();
    handleRefresh && handleRefresh();
  });

  /**
   * 处理预览
   * @param index 下标
   */
  const handlePreview = (index: number) => {
    // 初始化
    imgAngle = _.fill(Array(taskDetail?.images.length), 0);
    ImageViewer.Multi.show({
      images: taskDetail?.images,
      defaultIndex: index,
      classNames: { body: 'safe-padding-top' },
      renderFooter: (v: string, i: number) => (
        <div className="safe-padding-bottom">
          <div className="p-4 flex justify-center items-center text-center bg-[rgba(0,0,0,.45)]">
            <div className={`operate-btn ml-4`} onClick={() => handleRotate(i, 'left')}>左旋转</div>
            <div className={`operate-btn ml-4`} onClick={() => handleRotate(i, 'right')}>右旋转</div>
          </div>
        </div>
      )
    })
  };

  /**
   * 处理旋转
   * @param index 下标
   * @param direction 方向
   */
  const handleRotate = (index: number, direction: string) => {
    // 处理角度
    const angle = direction === 'left' ? imgAngle[index] - 90 : imgAngle[index] + 90;
    const obj: any = document.querySelector(`.adm-image-viewer-slides-inner .adm-image-viewer-slide:nth-child(${index + 1})`);
    obj.style = `rotate: ${angle}deg`;
    _.fill(imgAngle, angle, index, index + 1);
  };

  // 处理评价
  const getData = useMutation(async (params: any) => {
    try {
      setEvaluate(true);
      // 解构
      const { dataList, cursor } = params;
      // 获取数据
      const res: any = await teacherAnswerComment( data?.id, cursor);
      setDataList([...dataList, ...res?.list]);
      setCursor(res?.list.length >= 50 ? res?.cursor : null);
      setOtherInfo({ avg_rating: res?.avg_rating });
    } catch (err) {
      console.log(err);
    }
  });

  // 加载更多
  const loadMore = (): any => {
    if(!getData.isLoading && dataList.length !== 0) {
      getData.mutate({ dataList, cursor });
    }
  };

  // 监听
  useEffect(() => {
    feedBack ? setCancelReason('年级错误') : '';
  }, [feedBack]);

  // 监听
  useEffect(() => {
    cancelReason === '其他' ? setContent('') : '';
  }, [cancelReason]);

  // // 初始化
  // useEffect(() => {
  //   timer && clearInterval(timer);
  //   visible && setTime(5);
  //   return () => timer && clearInterval(timer);
  // }, [visible]);

  // // 定时器
  // useEffect(() => {
  //   visible ? time === second ? timer = setInterval(() => setTime(time => --time), 1000) : time <= 0 ? clearInterval(timer) : '' : '';
  // }, [time, visible]);

  return (
    <div className="">
      <div className="py-2 pl-2 pr-4 bg-white rounded-xl flex justify-between items-center" onClick={() => handleInfo.isLoading ? '' : handleInfo.mutate()}>
        <div className={`w-[50px] h-[50px] rounded-md ${TASK_ITEM_COLOR[data.subject]} text-[13px] family-PingFangSC-PingFang font-medium flex flex-col justify-center items-center text-center`}>
          <div className="">{GRADE_DATA[data.grade_code]}</div>
          <div className="">{data.subject.replace('道德与法治', '道法')}</div>
        </div>
        <div className="ml-3 flex-1">
          <div className="relative">
            <div className="text-[14px] family-PingFangSC-PingFang font-medium text-[#232323] truncate-1">{`${data.question_type ? `[${data.question_type}]·` : ''}`}{data.desc}</div>
            {
              data?.unread && <span className="absolute right-0 top-0 flex h-2 w-2">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-500 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-2 w-2 bg-red-500"></span>
              </span>
            }
          </div>
          <div className="mt-1.5 flex items-center text-[12px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.45)]">
            <div className="flex-1 flex items-center">
              <div className="flex items-center">
                <img src={(data.status === 4 || data.status === 5) && data?.teacher_award_status !== 2 ? IncomeDefault : IncomeActive} alt="" className="w-[14px] h-[14px]"/>
                <div className={`ml-1 ${(data.status === 4 || data.status === 5) ? '' : 'text-[#232323]'}`}>{data?.teacher_income}</div>
              </div>
              <div className="">{showTime}</div>
              {data?.avg_rating !== 0 && <div className={`text-[12px] family-PingFangSC-PingFang text-[rgba(255,167,69,1)] ${showTime ? '' : 'ml-1.5'}`}>{data?.avg_rating}分</div>}
            </div>
            <div className="ml-1.5 flex items-center">
              <img src={data.status === 5 ? CancelIcon : data?.from_type === 1 ? UserIcon : OtherIcon} alt="" className="w-[14px] h-[14px]"/>
              <div className="ml-1 text-[11px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.45)]">{data.status === 5 ? '已取消' : data?.from_type === 1 ? '一对一' : '第三方'}</div>
            </div>
          </div>
        </div>
      </div>
      <Popup visible={visible} onMaskClick={() => setVisible(false)} className="w-full" bodyStyle={{borderTopLeftRadius: '1.25rem', borderTopRightRadius: '1.25rem'}}>
        <div className="safe-padding-bottom max-h-[90vh] overflow-auto">
          <div className="p-4">
          <div className="flex justify-between items-centerr">
              <div className="text-[16px] family-PingFangSC-PingFang font-semibold text-[#232323]">问题详情</div>
              {
                taskDetail?.status === 2 && <div className="flex items-center hint-btn" onClick={() => setFeedBack(true)}>
                  <div className="text-[13px] family-PingFangSC-PingFang text-[rgba(35,35,35,0.65)]">问题有误</div>
                  <img src={HintIcon} alt="" className="ml-1 w-3 h-3"/>
                </div>
              }
            </div>
            {
              taskDetail?.images && <div className="mt-4 grid grid-cols-3 gap-3">
                {
                  taskDetail?.images.slice(0, 3).map((item, index: number) => (
                    <div key={index} className="" onClick={() => handlePreview(index)}>
                      {index <= 1 ? <img src={item} alt="" className="w-[106px] h-[106px] rounded-xl object-cover"/> : index === 2 ? <div className="relative">
                        <img src={item} alt="" className="w-[106px] h-[106px] rounded-xl object-cover"/>
                        {taskDetail?.images.length > 3 && <div
                          className="w-[106px] h-[106px] rounded-xl bg-[rgba(0,0,0,0.65)] absolute top-0 left-0 text-[32px] family-PingFangSC-PingFang font-semibold text-white flex justify-center items-center">+{taskDetail?.images.length - 3}</div>}
                      </div> : ''}
                    </div>
                  ))
                }
              </div>
            }
            <div className="mt-3 text-[14px] family-PingFangSC-PingFang text-[rgba(36,36,36,0.65)] leading-6">{`${taskDetail?.question_type ? `[${taskDetail?.question_type}]·` : ''}`}{taskDetail?.desc}</div>
            <div className="mt-3 p-3 bg-[#F2F3F5] rounded-xl">
              {
                taskDetail?.status === 4 && taskDetail?.from_type !== 1 ? <div className="flex justify-between items-center">
                  <div className="flex-1 flex items-center">
                    <div className="text-[14px] family-PingFangSC-PingFang text-[#232323]">综合评分：</div>
                    {taskDetail?.avg_rating !== 0 ? <div className="text-[20px] family-PingFangSC-PingFang font-semibold text-[rgba(255,167,69,1)]">{taskDetail?.avg_rating}分</div> :
                      <div className="text-[14px] family-PingFangSC-PingFang text-[rgba(36,36,36,0.65)]">暂无评分</div>}
                  </div>
                  {
                    taskDetail?.avg_rating !== 0 && <div className="flex items-center" onClick={() => getData.isLoading ? '' : getData.mutate({ dataList: [], cursor: '' })}>
                      <div className="text-[14px] family-PingFangSC-PingFang text-[rgba(36,36,36,0.45)]">全部评价</div>
                      <img src={RightIcon} alt="" className="ml-0.5 w-4 h-4"/>
                    </div>
                  }
                </div> : <div className="flex justify-between items-center">
                  <div className="flex-1 flex items-center">
                    <Avatar src={taskDetail?.user.headimgurl || DefaultAvatar} style={{'--size': '2.5rem', '--border-radius': '1.25rem'}}/>
                    <div className="ml-2.5 flex-1 text-[14px] family-PingFangSC-PingFang font-medium text-[#5B6A92] flex-wrap truncate-2">{taskDetail?.user.nickname}</div>
                  </div>
                  <div className="ml-3 ask-question-count">已提问：{taskDetail?.user.ask_num}</div>
                </div>
              }
              {
                taskDetail?.status === 4 && taskDetail?.from_type === 1 && taskDetail?.comment && <div className="">
                  <Rate allowHalf className="mt-3" readOnly value={taskDetail?.comment?.rating}
                        style={{'--star-size': '.75rem', '--inactive-color': 'rgba(36,36,36,0.15)', '--active-color': 'rgba(255, 167, 69, 1)'}}/>
                  {taskDetail?.comment?.content && <div className="mt-1 text-[14px] family-PingFangSC-PingFang text-[rgba(36,36,36,0.65)] leading-6">{taskDetail?.comment?.content}</div>}
                </div>
              }
            </div>
            {
              (taskDetail?.status === 4 || taskDetail?.status === 5) && <div className="text-[14px] family-PingFangSC-PingFang">
                <div className="mt-3.5 flex items-center">
                  <div className="text-[rgba(36,36,36,0.45)] w-[70px]">接单时间：</div>
                  {taskDetail?.order_task_time && <div className="text-[#232323]">{dayjs(taskDetail?.order_task_time).format("YYYY-MM-DD HH:mm:ss")}</div>}
                </div>
                <div className="mt-2.5 flex items-center">
                  <div className="text-[rgba(36,36,36,0.45)] w-[70px]">完成时间：</div>
                  {taskDetail?.finish_time && <div className="text-[#232323]">{dayjs(taskDetail?.finish_time).format("YYYY-MM-DD HH:mm:ss")}</div>}
                </div>
                <div className="mt-2.5 flex items-center">
                  <div className="text-[rgba(36,36,36,0.45)] w-[70px]">发放状态：</div>
                  <div className="flex items-center">
                    <div className={`${TASK_AWARD_STATUS_TEXT_COLOR[taskDetail?.teacher_award_status]}`}>{TASK_AWARD_STATUS[taskDetail?.teacher_award_status]}</div>
                    {(taskDetail?.teacher_award_status === 1 || taskDetail?.teacher_award_status === 2) && taskDetail?.reward_time &&
                      <div className="text-[rgba(36,36,36,0.45)]">（{dayjs(taskDetail?.reward_time).format("YYYY-MM-DD HH:mm:ss")}）</div>}
                  </div>
                </div>
                <div className="mt-2.5 flex items-center">
                  <div className="text-[rgba(36,36,36,0.45)] w-[70px]">佣金：</div>
                  <div className="text-[#232323]">&#165;{taskDetail?.teacher_income}</div>
                </div>
                {
                  taskDetail?.status === 5 && <div className="mt-2.5 flex items-center">
                    <div className="text-[rgba(36,36,36,0.45)] w-[70px]">取消原因：</div>
                    <div className="text-[#232323] flex-1">{taskDetail?.cancel_reason}</div>
                  </div>
                }
              </div>
            }
            {!_.includes(blacklist, loginInfo?.username) ? (taskDetail?.status === 4 || taskDetail?.status === 5) ? <div className="mt-6 submit-btn" onClick={() => handleJump(taskDetail?.chat_id)}>查看解答</div> :
              <div className={`mt-6 ${handleOrder.isLoading || time !== 0 ? 'opacity-70' : ''}`} onClick={() => handleOrder.isLoading || time !== 0 ? '' : handleOrder.mutate()}>
                {
                  time === 0 ? <div className="submit-btn">
                    <div className="">立即接单</div>
                    {taskDetail?.teacher_income &&
                      <div className="align-bottom">（佣金 <span className="text-[12px] text-[#FF5C4E] ">&#165;</span><span className="text-[#FF5C4E]">{taskDetail?.teacher_income}</span>）</div>}
                  </div> : <div className="submit-btn">请先查看题目（{time}s）</div>
                }
              </div> : ''}
          </div>
        </div>
      </Popup>
      <CenterPopup visible={isShow} onMaskClick={() => toggle(false)} className="w-full" style={{'--border-radius': '1rem', '--min-width': '80vw'}}>
        <div className="p-4">
          <div className="text-[18px] family-PingFangSC-PingFang font-semibold text-[#232323] text-center">提示</div>
          <div className="mt-4 text-[15px] family-PingFangSC-PingFang text-[rgba(36,36,36,0.65)] text-center">{msg}</div>
          <div className={`mt-10 submit-btn`} onClick={() => toggle(false)}>确定</div>
        </div>
      </CenterPopup>
      <Popup visible={feedBack} onMaskClick={() => setFeedBack(false)} className="w-full" bodyStyle={{borderTopLeftRadius: '1.25rem', borderTopRightRadius: '1.25rem'}}>
        <div className="safe-padding-bottom max-h-[75vh] overflow-auto">
          <div className="p-4">
            <div className="text-[16px] family-PingFangSC-PingFang font-semibold text-[#232323]">错误原因</div>
            <div className="mt-4">
              <div className="grid grid-cols-3 gap-x-3 gap-y-4">
                {
                  items.map(item => (
                    <div key={item} className={`${cancelReason === item ? 'primary-btn' : 'default-btn'}`} onClick={() => setCancelReason(item)}>{item}</div>
                  ))
                }
              </div>
              {
                cancelReason === '其他' && <div className="mt-4">
                  <TextArea placeholder="请输入错误原因，最多100个字符" rows={4} value={content} onChange={(val) => setContent(val)} maxLength={100} className="custom-textarea"
                            style={{'--placeholder-color': 'rgba(36,36,36,0.45)'}}/>
                </div>
              }
            </div>
            <div className={`mt-10 submit-btn ${handleFeedback.isLoading ? 'opacity-70' : ''}`} onClick={() => handleFeedback.isLoading ? '' : handleFeedback.mutate()}>确定</div>
          </div>
        </div>
      </Popup>
      <Popup visible={evaluate} onMaskClick={() => setEvaluate(false)} className="w-full" bodyStyle={{borderTopLeftRadius: '1.25rem', borderTopRightRadius: '1.25rem'}}>
        <div className="safe-padding-bottom max-h-[75vh] overflow-auto">
          <div className="p-4">
            <div className="text-[16px] family-PingFangSC-PingFang font-semibold text-[#232323]">全部评价</div>
            <div className="mt-3 flex items-center pb-4 half-1px-line">
              <div className="text-[14px] family-PingFangSC-PingFang text-[#242424]">综合评分</div>
              <Rate allowHalf className="ml-4" readOnly value={otherInfo?.avg_rating}
                    style={{'--star-size': '1rem', '--inactive-color': 'rgba(36,36,36,0.15)', '--active-color': 'rgba(255, 167, 69, 1)'}}/>
              <div className="ml-4 text-[24px] family-D-DIN-Bold text-[#FFA745]">{otherInfo?.avg_rating}</div>
            </div>
            {
              dataList.map((item: any) => (
                <div key={item?.id} className="py-3 half-1px-line">
                  <Rate allowHalf className="" readOnly value={item?.rating}
                        style={{'--star-size': '1rem', '--inactive-color': 'rgba(36,36,36,0.15)', '--active-color': 'rgba(255, 167, 69, 1)'}}/>
                  {item?.content && <div className="mt-2 text-[14px] family-PingFangSC-PingFang text-[rgba(36,36,36,0.65)]">{item?.content}</div>}
                </div>
              ))
            }
            <InfiniteScroll loadMore={loadMore} hasMore={cursor !== null}/>
          </div>
        </div>
      </Popup>
    </div>
  )
}