import { useQuery } from "react-query";
import Layout from "@/components/Layout";
import SafeNav from "@/components/SafeNav";
import { getArticle } from "@/services";

export default function DeviceProperties() {
  // 获取内容
  const { data } = useQuery([getArticle.name], async () => {
    // 获取数据
    const { article } = await getArticle(5061);
    return article.content;
  });

  return (
    <Layout paddingBottom={false}>
      <SafeNav title="设备属性信息" isService={false}/>
      <div className="min-h-screen bg-[#F7F7F7]">
        <div className="pt-[42px]">
          {
            data && <div className="p-4 pt-0 h-full overflow-scroll">
              <div className="text-[#666] text-[0.88rem] leading-[1.31rem] break-all">
                <div dangerouslySetInnerHTML={{__html: data}} style={{lineHeight: '1.8em'}}/>
              </div>
            </div>
          }
        </div>
      </div>
    </Layout>
  )
}