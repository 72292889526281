import { useCallback, useEffect } from 'react';
import { useLoginInfo } from './user';
import { Toast } from 'antd-mobile';
import { requestOnePermission } from '@/apicloud/api';
import { hasOnePermission, isIOS, openWin, isApp } from "@/utils";
import { toUTF8Hex } from '@/utils/crypto';
import BrowserLogger from "alife-logger";
import { JPUSH_ID_KEY } from '@/constants/common';
import { bindPushID, loginByQrCode } from '@/services';
import { useRecoilState } from "recoil";
import { ServicePopupState } from "@/models/dict";
import { openFNScan } from "@/apicloud/modules/FNScanner";
import _ from "lodash";
import { ScanPopupState } from "@/models/common";

// 打开客服页面
export const useGoService = (from: any) => {
  // 用户登录信息
  const { loginInfo } = useLoginInfo();
  // 弹窗状态
  const [, setVisible] = useRecoilState(ServicePopupState);

  // 处理打开
  const go = useCallback(async () => {
    setVisible(true);
    return false;
    const url = `https://h5.daanjiexi.com/chat.html?type=4&t=${new Date().getTime()}&hiddenbar=true&from=${toUTF8Hex(JSON.stringify({
      ...from,
      client: isIOS() ? 'IOS' : 'ANDROID',
    }))}&top=${window.api?.safeArea.top || ''}&bottom=${window.api?.safeArea.bottom || ''}&token=${localStorage.getItem("token") || ""}`;
    // 校验通知权限
    if (isApp()) {
      const has = await hasOnePermission("notification");
      if (has.granted) {
        openWin(url, "ServiceChat");
      } else {
        window.api.confirm({
          msg: '为了接收客服消息，请允许通知权限',
          buttons: ['去开启', '取消']
        }, async (ret) =>  {
          if(ret.buttonIndex != 1) {
            return
          }
          try {
            await requestOnePermission("notification");
            openWin(url, "ServiceChat");
          } catch (error) {
            Toast.show("为了接收客服消息，请允许通知权限");
          }
        });
      }
    } else {
      openWin(url, "ServiceChat");
    }
  }, [from, loginInfo]);

  return go;
};

export function useInitARMS({ uid, tag, release }: { uid: string | number, tag: string, release: string }) {
  useEffect(() => {
    if (import.meta.env.MODE === "production") {
      // 初始化阿里云 ARMS
      window.__bl = BrowserLogger.singleton(
        {
          pid: "f67zpk1btw@f0934b1a09e6224",
          appType: "web",
          imgUrl: "https://arms-retcode.aliyuncs.com/r.png?",
          behavior: true,
          // disableHook: true, // 禁用 api 数据上报
          enableSPA: true,
          uid, // 订单号
          tag,
          release,
          environment: import.meta.env.MODE === 'production' ? 'prod' : 'local' 
        }
      );
    } else {
      window.__bl = {
        sum: (name: string) => console.log(`【arms 上报】${name}`)
      }
    }
  }, [])
}

// 初始化推送模块
export function initJPushModule() {
  window.modules.jpushVip && window.modules.jpushVip.init((ret: any, err: any) => {
    if (ret?.status) {
      console.log("极光 SDK 初始化成功")

      // 如果没有获取过极光推送ID，则获取并提交给服务端
      if (!localStorage.getItem(JPUSH_ID_KEY)) {
        window.modules.jpushVip.getRegistrationId((res: any, err: any) => {
          if (res?.id) {
            // 获取推送ID成功，写入local，用于后续绑定
            bindPushID(res.id).then(() => {
              localStorage.setItem(JPUSH_ID_KEY, res.id)
            }).catch(() => {
              console.log("bind jiguang id error")
            })
          } else {
            console.log('get jiguang id error')
          }
        })
      }
    }
  })
}

// 扫码
export const useScan = () => {
  // 弹窗状态
  const [visible, setVisible] = useRecoilState(ScanPopupState);

  // 处理扫码
  const handleOpenScan = async () => {
    if (!isApp()) {
      setVisible(true);
      return false;
    }
    try {
      // 扫码
      const { content }: any = await openFNScan();
      // 处理无内容
      if (!content) {
        Toast.show({ content: '图片无法识别', icon: 'fail' });
        return false;
      }
      // 处理协议
      if (_.startsWith(content, 'code_login://')) {
        const code = content.replace('code_login://', '');
        code ? await loginByQrCode(code) : Toast.show({ content: '授权链接错误，请重试', icon: 'fail' });
      } else {
        Toast.show({ content: '无法识别，请重试', icon: 'fail' });
      }
    } catch (err: any) {
      Toast.show({ content: err?.message, icon: 'fail' });
    }
  };

  return { handleOpenScan };
};